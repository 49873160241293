import theme from 'styles/theme'
import styled from 'styled-components'
import { useState } from 'react'

const Style = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid ${theme.nd(0.6)};
  border-radius: 4px;
  .value {
    padding: 12px 16px;
    cursor: pointer;
  }
  .options {
    ${theme.card}
    z-index: 200;
    padding: 0;
    overflow: hidden;
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
  }
`

const OptionItemStyle = styled.div`
  padding: 16px 16px;
  border-bottom: 1px solid ${theme.nd(0.08)};
  cursor: pointer;
  transition: all 0.2s; 
  &:hover {
    background-color: ${theme.nd(0.04)};
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }
`

const OptionItem = ({ option, label, onClick }) => {
  return (
    <OptionItemStyle className="option-item" onClick={() => {
      onClick(option)
    }}>
      { option[label] }
    </OptionItemStyle>
  )
}

const Select = ({ value,
                  placeholder,
                  name,
                  options = [],
                  onChange = () => {},
                  keyName = "id",
                  label = "label"}) => {
  const [ collapsed, setCollapsed ] = useState(true)

  const toggle = () => setCollapsed(prev => !prev)

  const selectItem = option => {
    onChange({
      target: {
        name,
        value: option[keyName]
      }
    })
    setCollapsed(true)
  }

  const displayingValue = value ? options.filter(option => option[keyName] === value)[0][label] : placeholder

  return (
    <Style>
      <div className="value" onClick={toggle}>
        { displayingValue }
      </div>
      { !collapsed && <div className="options">
        { options.map(option => <OptionItem 
                                  key={option[keyName]}
                                  option={option}
                                  onClick={selectItem}
                                  label={label}/>)}
      </div>}
    </Style>
  )
}

export default Select
