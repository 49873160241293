import theme from 'styles/theme'
import styled from 'styled-components'
import { Input } from 'components/input'
import IcMinus from 'svg/IcMinus'
import { getNumberUnit } from 'utils'
import { memo } from 'react'
import { useCompanyRelation } from 'hooks'

const Style = styled.div`
  ${theme.card};
  padding: 8px 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr) 48px;
  column-gap: 8px;
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .remove-button {
    background-color: ${theme.negative(1)};
    border-radius: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .number-with-unit {
    padding-top: 4px;
    color: ${theme.nd(0.4)};
  }
`

const CompanyValueTemplate = ({ setArray, state }) => {
  
  const { onChange, removeValue } = useCompanyRelation({ 
    state,
    setArray
  })
  
  return (
    <Style>
      <Input
        name="title"
        value={state.title}
        placeholder="Series"
        onChange={onChange}/>
      <div className="number-input">
        <Input
          name="value"
          value={state.value}
          placeholder="Value"
          onChange={onChange}/>
        <div className="number-with-unit">
          Value = { getNumberUnit(state.value) }
        </div>
      </div>
      <div className="number-input">
        <Input
          name="funding"
          value={state.funding}
          placeholder="Funding"
          onChange={onChange}/>
        <div className="number-with-unit">
          Funding = { getNumberUnit(state.funding) }
        </div>
      </div>
      <div className="number-input">
        <Input
          name="value_per_stock"
          value={state.value_per_stock}
          placeholder="Value Per Stock"
          onChange={onChange}/>
        <div className="number-with-unit">
          Value per Stock = { getNumberUnit(state.value_per_stock) }
        </div>
      </div>
      <Input
        name="date_at"
        value={state.date_at}
        placeholder="YYYY-MM-DD"
        onChange={onChange}/>
      <div className="actions">
        <div className="remove-button" onClick={removeValue}>
          <IcMinus width={16} height={16} color={theme.nl(1)} stroke={2}/>
        </div>
      </div>
    </Style>
  )
}

export default memo(CompanyValueTemplate)
