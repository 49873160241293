import theme from 'styles/theme'
import styled from 'styled-components'
import IcPlus from 'svg/IcPlus'

const Style = styled.div`
  background-color: ${theme.positive(1)};
  width: 20px;
  height: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
`

const MoreButton = ({ onClick = () => {} }) => {
  return (
    <Style onClick={onClick}>
      <IcPlus color={theme.nl(1)} stroke={2}/>
    </Style>
  )
}

export default MoreButton
