import theme from 'styles/theme'
import styled, { css } from 'styled-components'
import IcPlus from 'svg/IcPlus'
import { getExt } from 'utils'
import axios from 'axios'
import { memo } from 'react'

const Style = styled.div`
  input {
    display: none;
  }
  label {
    width: ${({width}) => width || '150px'};
    height: ${({height}) => height || '150px'};
    overflow: hidden;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ url }) => url ? css`
      background-image: url(${({url}) => url});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid ${theme.nd(0.1)};
    ` : css`
      border: 3px dashed ${theme.positive(0.5)};
    `}
  }
`

const UploadButton = ({ onChange, width, height, url, name, id, type="companies" }) => {

  const makeFilename = file => {
    const {fileName, ext} = getExt(file.name);
    const fullName = `${fileName}${ext}`;
    return fullName
  }

  const customOnChange = async e => {
    if (e.target.files.length > 0) {
      const formData = new FormData()
      const file = e.target.files[0];
      formData.append('upload', file, makeFilename(file))
      
      const result = await axios.post(`/admin/${type}/upload`, formData, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      })

      const fileParams = {
        target: {
          name: name,
          value: result.data
        }
      }
      onChange(fileParams)
    }
  }

  return (
    <Style url={url} width={width} height={height}>
      <input type="file" id={id} onChange={customOnChange}/>
      <label htmlFor={id}>
        { !url ? <IcPlus/> : <></>}
      </label>
    </Style>
  )
}

export default memo(UploadButton)
