import styled from 'styled-components'
import DefaultLayout from 'layout/default'

const Style = styled.div`

`

const AdminHome = () => {
  return (
    <DefaultLayout>
      <Style>
        
      </Style>
    </DefaultLayout>
  )
}

export default AdminHome
