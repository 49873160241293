const dictionary = {
  menu: {
    home: '홈',
    deal: '클럽딜',
    company: '해외 기업정보',
    about: '트위그 소개'
  },
  deal_status: {
    PENDING: '모집 예정',
    SALE: '진행 중',
    ENDED: '모집 완료',
    CANCELED: '진행 중단',
  },
  order_status: {
    PENDING: '입금 대기 중',
    CONTRACT_COMPLETION: '약정 완료',
    CONFIRM: '입금 완료됨',
    CANCELED: '주문 취소됨',
    PARTIALLY_CONFIRM: '일부 환불 예정',
    AMOUNT_DISMATCH: '입금금액 불일치',
    OVER_FUNDING: '초과 입금됨',
    WILL_REFUND: '환불 예정',
    REFUND: '환불 완료',
  },
  deal_progress: {
    SALE: '클럽 딜 오픈',
    UNION_CREATING: '조합 설립 중',
    UNION_CREATED: '조합 설립 완료',
    COMPLETED_BUYING: '매수 완료',
    EXIT: '종료',
    CANCELED: '클럽 딜 취소',
  },
  faq_category: {
    union: '조합',
    funding: '출자',
    exit: '회수',
    general: '일반'
  },
  grade: [
    '관리자',
    '트위그 파트너',
    '출자자',
    ''
  ]
}

export default dictionary
