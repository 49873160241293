import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default'
import { useInit, useInput } from 'hooks'
import { useCallback, useState } from 'react'
import axios from 'axios'
import { Division } from 'components'
import { Button } from 'components/button'
import MoreButton from 'components/button/MoreButton'
import { useParams } from 'react-router-dom'
import { useToast } from 'components/toast/Toast'
import CompanyTemplate from 'components/template/CompanyTemplate'
import CompanyValueTemplate from 'components/template/CompanyValueTemplate'
import MemberTemplate from 'components/template/MemberTemplate'
import CompetitorTemplate from 'components/template/CompetitorTemplate'
import NewsTemplate from 'components/template/NewsTemplate'

let innerId = 0;

const Style = styled.div`
  ${theme.card};
  .grid-list {
    display: grid;
    padding: 16px 0;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  .submit-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
`

const CompanyDetail = () => {
  const params = useParams()
  const { addToast } = useToast()
  const [ state, onChange ] = useInput({
    uuid: null,
    id: null,
    name: '',
    kr_name: '',
    url: '',
    established: '',
    introduction: '',
    detail: '',
    investors: '',
    is_active: false,
    icon: '',
    banner: '',
    tags: [],
    twitter: '',
    newTag: '',
  })

  const [ companyValues, setCompanyValues ] = useState([])
  const [ members, setMembers ] = useState([])
  const [ competitors, setCompetitors ] = useState([])
  const [ news, setNews ] = useState([])

  const setData = result => {
    for (let key in result.data) {
      onChange({
        target: {
          name: key,
          value: result.data[key]
        }
      })
    }

    setCompanyValues(result.data.company_funding.reduce((acc, value) => {
      acc.push({
        ...value,
        innerId: innerId++,
        company: result.data.id,
        is_delete: false,
      })
      return acc
    }, []))

    setMembers(result.data.members.reduce((acc, value) => {
      acc.push({
        ...value,
        innerId: innerId++,
        company: result.data.id,
        is_delete: false,
      })
      return acc
    }, []))

    setCompetitors(result.data.competitors.reduce((acc, value) => {
      acc.push({
        ...value,
        innerId: innerId++,
        company: result.data.id,
        is_delete: false,
      })
      return acc
    }, []))

    setNews(result.data.news.reduce((acc, value) => {
      acc.push({
        ...value,
        innerId: innerId++,
        company: result.data.id,
        is_delete: false,
      })
      return acc
    }, []))
  }

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/companies/${params.uuid}`)
    setData(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.uuid])

  useInit(fetchData)

  const addValue = () => {
    setCompanyValues(prev => [
      ...prev,
      {
        innerId: innerId++,
        company: state.id,
        title: "",
        date_at: "",
		    value: "",
		    funding: "",
		    value_per_stock: "",
        is_delete: false,
      }
    ])
  }

  const addMember = () => {
    setMembers(prev => [
      ...prev,
      {
        innerId: innerId++,
        company: state.id,
        image: "",
        name: "",
        position: "",
        group: null,
        linked_in: "",
        is_delete: false,
      }
    ])
  }

  const addCompetitors = () => {
    setCompetitors(prev => [
      ...prev,
      {
        innerId: innerId++,
        company: state.id,
        icon: "",
        name: "",
        introduction: "",
        is_delete: false,
      }
    ])
  }

  const addNews = () => {
    setNews(prev => [
      ...prev,
      {
        innerId: innerId++,
        company: state.id,
        source: "",
        summary: "",
        title: "",
        url: "",
        date_at: "",
        is_delete: false,
      }
    ])
  }

  const saveCompany = async () => {
    if (state.icon === "") return addToast('아이콘을 등록해주세요', 'negative')

    if (state.banner === "") return addToast('배너를 등록해주세요', 'negative')

    const params = { ...state }
    delete params.newTag

    await axios.patch(`/admin/companies/${state.uuid}`, params)
    addToast('회사정보 저장 성공')
  }

  const saveCompanyValue = async () => {
    const result = await axios.post('/admin/fundings', companyValues.reduce((acc, value) => {
      const item = { ...value }
      delete item.innerId
      acc.push(item)
      return acc
    }, []))
    setCompanyValues(result.data.reduce((acc, value) => {
      acc.push({
        ...value,
        company: state.id,
        is_delete: false,
        innerId: innerId++,
      })
      return acc
    }, []))
    addToast('기업가치 저장 성공')
  }

  const saveMember = async () => {
    const result = await axios.post('/admin/members', members.reduce((acc, value) => {
      const item = { ...value }
      delete item.innerId
      acc.push(item)
      return acc
    }, []))
    setMembers(result.data.reduce((acc, value) => {
      acc.push({
        ...value,
        company: state.id,
        is_delete: false,
        innerId: innerId++,
      })
      return acc
    }, []))
    addToast('멤버 저장 성공')
  }

  const saveCompetitors = async () => {
    const result = await axios.post('/admin/competitors', competitors.reduce((acc, value) => {
      const item = { ...value }
      delete item.innerId
      acc.push(item)
      return acc
    }, []))
    setCompetitors(result.data.reduce((acc, value) => {
      acc.push({
        ...value,
        company: state.id,
        is_delete: false,
        innerId: innerId++,
      })
      return acc
    }, []))
    addToast('경쟁사 저장 성공')
  }

  const saveNews = async () => {
    const result = await axios.post('/admin/news', news.reduce((acc, value) => {
      const item = { ...value }
      delete item.innerId
      acc.push(item)
      return acc
    }, []))
    setNews(result.data.reduce((acc, value) => {
      acc.push({
        ...value,
        company: state.id,
        is_delete: false,
        innerId: innerId++,
      })
      return acc
    }, []))
    addToast('뉴스 저장 성공')
  }

  return (
    <DefaultLayout>
      <Style>
        <h2>회사 정보 수정</h2>
        <CompanyTemplate state={state} onChange={onChange}/>
        
        <div className="submit-action">
          <Button primary width="200px" onClick={saveCompany}>회사정보 저장</Button>
        </div>

        <Division height="48px"/>

        <div className="flex flex-v-center">
          <h2>기업가치</h2>
          <Division width="8px"/>
          <MoreButton onClick={addValue}/>
        </div>
        <div className="grid-list">
          { companyValues.filter(item => item.is_delete === false).map((item, index) => (
            <CompanyValueTemplate state={item} key={item.innerId} index={index} setArray={setCompanyValues}/>
          ))}
        </div>
        <div className="submit-action">
          <Button primary width="200px" onClick={saveCompanyValue}>
            기업가치 저장
          </Button>
        </div>

        <Division height="48px"/>

        <div className="flex flex-v-center">
          <h2>멤버</h2>
          <Division width="8px"/>
          <MoreButton onClick={addMember}/>
        </div>
        <div className="grid-list">
          { members.filter(item => item.is_delete === false).map((member, index) => (
            <MemberTemplate
              key={member.innerId}
              state={member}
              index={index}
              setArray={setMembers}/>
          ))}
        </div>
        <div className="submit-action">
          <Button primary width="200px" onClick={saveMember}>
            멤버 저장
          </Button>
        </div>

        <Division height="48px"/>

        <div className="flex flex-v-center">
          <h2>경쟁사</h2>
          <Division width="8px"/>
          <MoreButton onClick={addCompetitors}/>
        </div>
        <div className="grid-list">
          { competitors.filter(item => item.is_delete === false).map((competitor, index) => (
            <CompetitorTemplate
              key={competitor.innerId}
              state={competitor}
              index={index}
              setArray={setCompetitors}/>
          ))}
        </div>
        <div className="submit-action">
          <Button primary width="200px" onClick={saveCompetitors}>
            경쟁사 저장
          </Button>
        </div>

        <Division height="48px"/>

        <div className="flex flex-v-center">
          <h2>뉴스 &#38; 아티클</h2>
          <Division width="8px"/>
          <MoreButton onClick={addNews}/>
        </div>
        <div className="grid-list">
          { news.filter(item => item.is_delete === false).map((newsItem, index) => (
            <NewsTemplate
              key={newsItem.innerId}
              state={newsItem}
              index={index}
              setArray={setNews}/>
          ))}
        </div>
        <div className="submit-action">
          <Button primary width="200px" onClick={saveNews}>
            뉴스 &#38; 아티클 저장
          </Button>
        </div>

      </Style>
    </DefaultLayout>
  )
}

export default CompanyDetail
