import theme from 'styles/theme'
import styled from 'styled-components'
import { Input } from 'components/input'
import IcMinus from 'svg/IcMinus'
import Select from 'components/input/Select'
import UploadButton from 'components/button/UploadButton'
import { memo } from 'react'
import { useCompanyRelation } from 'hooks'

const Style = styled.div`
  ${theme.card};
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 100px repeat(4, 1fr) 48px;
  column-gap: 8px;
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .remove-button {
    background-color: ${theme.negative(1)};
    border-radius: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .number-with-unit {
    padding-top: 4px;
    color: ${theme.nd(0.4)};
  }
`

const MemberTemplate = ({ state, setArray }) => {
  const groups = [
    {
      id: 1,
      label: 'CEO & Co-Founder'
    },
    {
      id: 2,
      label: 'C Level'
    },
    {
      id: 3,
      label: 'ETC'
    },
  ]

  const { onChange, removeValue } = useCompanyRelation({ 
    state,
    setArray
  })
  
  return (
    <Style>
      <UploadButton 
        onChange={onChange}
        width="84px"
        height="84px"
        url={state.image}
        id={`member-${state.innerId}=image`}
        name="image"/>
      <div className="flex flex-center">
        <Input
          name="name"
          value={state.name}
          placeholder="이름"
          onChange={onChange}/>
      </div>
      <div className="flex flex-center">
        <Input
          name="position"
          value={state.position}
          placeholder="직책"
          onChange={onChange}/>
      </div>
      <div className="flex flex-center">
        <Select
          name="group"
          options={groups}
          value={state.group}
          placeholder="그룹"
          onChange={onChange}/>
      </div>
      <div className="flex flex-center">
        <Input
          name="linked_in"
          value={state.linked_in}
          placeholder="링크드인"
          onChange={onChange}/>
      </div>
      <div className="actions">
        <div className="remove-button" onClick={removeValue}>
          <IcMinus width={16} height={16} color={theme.nl(1)} stroke={2}/>
        </div>
      </div>
    </Style>
  )
}

export default memo(MemberTemplate)
