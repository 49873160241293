import theme from 'styles/theme'
import styled from 'styled-components'
import { Division } from 'components'
import { Button } from 'components/button'
import { destroyToken } from 'utils/auth'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const Style = styled.div`
  ${theme.card}
  margin-top: 24px;
  .logo {
    width: 48px;
  }
  .status-badge {
    margin-left: 16px;
    padding: 4px 8px;
    border-radius: 4px;
    color: ${theme.nl(1)};
    font-weight: 600;
    background-color: ${({env}) => theme.ENV_STATUS[env]};
  }
`

const Gnb = () => {
  const history = useHistory()

  const logout = async () => {
    destroyToken()
    axios.defaults.headers.post['authorization'] = ''
    history.push('/login')
  }

  return (
    <Style className="flex f-v-center f-h-between" env={process.env.REACT_APP_ENV}>
      <div className="flex f-v-center">
        <img className="logo" src="/assets/twig-logo.svg" alt="twig logo"/>
        <Division width="8px"/>
        <span>CMS</span>
        <div className="status-badge">
          {process.env.REACT_APP_ENV}
        </div>
      </div>
      <div className="action">
        <Button secondary size="small" onClick={logout}>Logout</Button>
      </div>
    </Style>
  )
}

export default Gnb;