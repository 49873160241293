import styled from "styled-components"
import theme from "styles/theme"

const Style = styled.div`
  display: flex;
  .label {
    font-weight: 400;
    color: ${theme.nd(0.5)};
    width: 200px;
    margin-right: 16px;
  }
  .info {
    font-weight: 500;
  }
`

const KeyValueLabel = ({ label, info }) => {
  return (
    <Style>
      <div className="label">{ label }</div>
      <div className="info">{ info }</div>
    </Style>
  )
}

export default KeyValueLabel