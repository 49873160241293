import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';
import { Link, useParams } from 'react-router-dom';

const Style = styled.div`
  ${theme.card}
  h2 {
    a {
      color: ${theme.positive(1)};
    }
  }
`

const UserOrderList = () => {
  const parameters = useParams()
  const { userLikeHeaders } = useHeader()
  const [ data, setData ] = useState([])
  const [ pageLength, setPageLength ] = useState(1)
  const [ current, setCurrent ] = useState(1)

  const [ user, setUser ] = useState({
    email: "",
    uuid: "",
  })

  const fetchData = useCallback(async page => {
    if (page) {
      setCurrent(page)
    }

    if (!user.uuid) {
      const userResult = await axios.get(`/admin/users/${parameters.uuid}`)
      setUser(userResult.data)
    }

    const params = {}
    if (page) params.page = page
    if (parameters.uuid) params.user = parameters.uuid

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/likes?${queryString}`)
    setData(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setPageLength(len)
    }
    // eslint-disable-next-line
  }, [parameters.uuid])

  useInit(fetchData)

  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2><Link to={`/admin/user/${user.uuid}`}>{user.email}</Link> 의 알림신청 내역</h2>
        </div>
        <Division height="24px"/>
        <DataTable headers={userLikeHeaders} data={data}/>
        <Division height="64px"/>
        <Pagination current={current} length={pageLength} fetchData={fetchData}/>
        <Division height="64px"/>
      </Style>
    </DefaultLayout>
  )
}

export default UserOrderList
