import { useEffect, useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { appInit } from 'recoil/GlobalStore';

const reducer = (state, action) => {
  return {
    ...state,
    [action.name]: action.value
  };
}

export const useInput = initialForm => {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = (e) => {
    dispatch(e.target);
  };
  return [state, onChange];
}

export const useInit = callback => {
  const init = useRecoilValue(appInit)
  useEffect(() => {
    if (init) {
      callback()
    }
  }, [init, callback])
}

export const useScroll = () => {
  const [ state, setState ] = useState({
    x: 0,
    y: 0
  })
  const onScroll = () => {
    setState({
      y: window.scrollY,
      x: window.scrollX
    })
  }
  
  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", onScroll)
    }
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return state
}

export const useCompanyRelation = ({ state, setArray }) => {
  const onChange = e => {
    setArray(prev => {

      const index = prev.reduce((acc, value, index) => {
        if (value.innerId === state.innerId) {
          acc = index
        }
        return acc
      }, 0)

      return [
        ...prev.slice(0, index),
        {
          ...state,
          [e.target.name]: e.target.value
        },
        ...prev.slice(index + 1)
      ]
    })
  }

  const removeValue = () => {
    if (state.uuid) {
      setArray(prev => {

        const index = prev.reduce((acc, value, index) => {
          if (value.innerId === state.innerId) {
            acc = index
          }
          return acc
        }, 0)

        return [
          ...prev.slice(0, index),
          {
            ...state,
            is_delete: true,
          },
          ...prev.slice(index + 1)
        ]})
    } else {
      setArray(prev => {

        const index = prev.reduce((acc, value, index) => {
          if (value.innerId === state.innerId) {
            acc = index
          }
          return acc
        }, 0)

        return [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ]})
    }
  }

  return { onChange, removeValue }
}