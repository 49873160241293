import theme from 'styles/theme'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { BooleanLabel } from 'components/label/BooleanLabel'
import IconLabel from 'components/label/IconLabel'

const Style = styled.div`
  display: flex;
  ${theme.card}
  border-radius: 8px;
  padding: 16px;
`

const RowItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: ${({ width }) => `${width}px` || 'auto'};
  color: ${theme.nd(0.6)};
  line-height: 1.4;
  cursor: ${({ cursor }) => cursor};
  padding-right: 8px;
  a {
    color: ${theme.positive(1)};
  }
  &.key {
    display: none;
  }
  &.string {

  }
  &.strong {
    color: ${theme.nd(1)};
    font-weight: 500;
    transition: color 0.2s;
    &:hover {
      color: ${theme.positive(1)};
    }
  }
  &.date {

  }
  &.boolean {
    
  }
  &.number {

  }
`

const ListItem = ({ row, headers }) => {
  
  const renderValue = ({ row, name, type, display }) => {
    switch (type) {
      case 'boolean':
        return <BooleanLabel status={row[name]}/>
      case 'date':
        return dayjs(row[name]).format('YYYY-MM-DD')
      case 'image':
        return <IconLabel src={row[name]}/>
      case 'custom': 
        return display(row)
      default: 
        return row[name];
    }
  }

  const computedType = key => {
    return headers.filter(header => {
      return header.name === key
    })[0].type
  }

  return (
    <Style>
      { 
        headers.map((header) => (
          <RowItem 
            className={`list-item ${computedType(header.name)}`}
            key={header.name} 
            width={header.width}
            onClick={() => {
              if (header.onClick) {
                header.onClick(row)
              }
            }}
            cursor={header.onClick ? 'pointer' : 'auto'}>
            {
              renderValue({ 
                row: row,
                name: header.name,
                type: computedType(header.name),
                display: header.display,
              })
            }
          </RowItem>
        ))
      }    
    </Style>
  )
}

export default ListItem
