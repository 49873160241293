import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { useInit } from 'hooks';
import { Division } from 'components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Style = styled.div`
  ${theme.card}
  .search-area {
    display: flex;
    gap: 8px;
  }
`

const DealStatsCount = () => {
  const params = useParams()
  const [state, setState] = useState({
    labels: [], 
    datasets: [
      {
        label: 'portfolio_count',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        data: []
      }
    ]
  })

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/report/deals/${params.uuid}/count`)
    setState({
      labels: result.data.amount, 
      datasets: [
        {
          label: 'portfolio_count',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          data: result.data.count
        }
      ]
    })
  }, [params.uuid])

  useInit(fetchData)



  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2>출자 건수 통계</h2>
        </div>
        <Division height="24px"/>
        <Bar
          data={state}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            ticks: {
              stepSize: 5
            }
          }}
        />
      </Style>
    </DefaultLayout>
  )
}

export default DealStatsCount
