import { css } from "styled-components";

const setAlpha = (rgb, alpha) => alpha === 0 || alpha ? `rgba(${rgb}, ${alpha})` : `rgba(${rgb}, 1)`;

const convertHex = (rgb, alpha) => {
  const convertedValue = Math.floor(255 - (alpha * 255));
  return `rgba(${convertedValue}, ${convertedValue}, ${convertedValue}, 1)`;
}

const base = {
  // twig theme color
  twig: '#3FFFB5',
  twig_dark: '#11D397',
  twig_alpha: alpha => setAlpha('129,249,203', alpha),
  info: '#59c89d',
  twig_dim: '#d3f8e6',
  // main color theme
  primary: 'rgba(65,110,255, 1)',
  secondary: 'rgba(255,177,0,1)',
  pink: '#ff5654',
  green: '#04a276',
  // ui color theme
  // positive: alpha => alpha ? `rgba(65,110,255, ${alpha})` : 'rgba(65,110,255, 1)',
  positive: alpha => setAlpha('65,110,255', alpha),
  negative: alpha => setAlpha('239,31,65', alpha),
  alert: alpha => setAlpha('255,142,16', alpha),
  base: alpha => setAlpha('0,0,0', alpha),
  // neutral color variables
  nd: alpha => setAlpha('0,0,0', alpha),
  nl: alpha => setAlpha('255,255,255', alpha),
  nsd: alpha => convertHex('0, 0, 0', alpha),
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',
  card: css`
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 1px 4px 24px rgba(0,0,0,0.08);
    padding: 24px 16px;
  `,
  
}

const ENV_STATUS = {
  development: base.alert(1),
  staging: base.negative(1),
  production: base.twig_dark
}

const status = {
  status_orange: base.alert(1),
  status_red: base.negative(1),
  status_green: base.green,
  status_blue: base.positive(1),
}

const theme = {
  ...base,
  ...status,
  ENV_STATUS,
  deal_status: {
    PENDING: status.status_orange,
    SALE: status.status_blue,
    ENDED: status.status_green,
    CANCELED: status.status_red
  },
  order_status: {
    PENDING: status.status_orange,
    CONFIRM: status.status_green,
    PARTIALLY_CONFIRM: status.status_orange,
    AMOUNT_DISMATCH: status.status_orange,
    OVER_FUNDING: status.status_orange,
    CANCELED: status.status_red,
    WILL_REFUND: status.status_red,
    REFUND: status.status_red,
  },
}

export default theme
