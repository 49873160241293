import theme from 'styles/theme'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { currentUser } from 'recoil/GlobalStore'
import { useRecoilValue } from 'recoil'

const Style = styled.div`
  ${theme.card}
  width: 200px;
  padding: 24px 24px;
  margin-right: 16px;
  .item {
    font-weight: 600;
    font-size: 16px;
    padding: 12px 0;
  }
`

const Sidebar = () => {
  const { staff_grade } = useRecoilValue(currentUser)
  if (staff_grade === 0) return <></>

  return (
    <Style>
      <div className="item">
        <Link to="/admin/company">
          회사 정보 관리  
        </Link>
      </div>
      { (staff_grade === 2 || staff_grade === 3) && (
        <>
        <div className="item">
          <Link to="/admin/deal">
            클럽딜 관리
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/comment">
            DEAL 댓글
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/post">
            POST 관리
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/postcomment">
            POST 댓글
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/order">
            출자 관리
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/user">
            회원 관리
          </Link>
        </div>
        <div className="item">
          <Link to="/admin/user/stats/signup">
            가입 통계
          </Link>
        </div>
        </>
      )}
    </Style>
  )
}

export default Sidebar
