import config from 'config/config';
import { useHistory } from 'react-router-dom';
import { numberWithCommas } from 'utils';
import dictionary from 'utils/translator';

const useHeader = () => {
  const history = useHistory()

  const companyHeaders = [
    {
      name: 'uuid',
      type: 'key',
      width: 60
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '회사명',
      name: 'name',
      type: 'strong',
      width: 240,
      onClick: row => {history.push(`/admin/company/${row.uuid}`)}
    },
    {
      label: '소개',
      name: 'introduction',
      type: 'string',
      width: 400
    },
    {
      label: '활성',
      name: 'is_active',
      type: 'boolean',
      width: 120
    },
    {
      label: '등록일',
      name: 'created',
      type: 'date',
      width: 140
    }
  ]

  const userHeaders = [
    {
      name: 'uuid',
      type: 'key',
      width: 60
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '이메일',
      name: 'email',
      type: 'strong',
      width: 240,
      onClick: row => {history.push(`/admin/user/${row.uuid}`)}
    },
    {
      label: '이름',
      name: 'name',
      type: 'string',
      width: 100
    },
    {
      label: '닉네임',
      name: 'nickname',
      type: 'string',
      width: 140
    },
    {
      label: '가입일',
      name: 'date_joined',
      type: 'date',
      width: 100
    }
  ]

  // "id": 54,
  // "uuid": "c242c04f-5407-4174-b6d3-ee6f1047ae52",
  // "icon": null,
  // "name": "94's testarossa 512TR",
  // "title": "테스타로사 클럽딜 1호",
  // "total_amount": "110000000.0000",
  // "ordered_amount": "3700000.0000",
  // "recruitment_percentage": "3.3636",
  // "total_seat": 1000,
  // "ordered_seat": 4,
  // "start_at": "2021-11-07T17:13:16+09:00",
  // "ended_at": "2021-11-15T17:13:19+09:00",
  // "finished_at": null,
  // "deal_status": "ENDED"

  const dealHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '로고',
      name: 'icon',
      type: 'image',
      width: 100
    },
    {
      label: '딜 이름',
      name: 'title',
      type: 'strong',
      width: 240,
      onClick: row => {history.push(`/admin/deal/${row.uuid}`)}
    },
    {
      label: '모집율',
      name: 'recruitment_percentage',
      type: 'custom',
      width: 200,
      display: row => `${numberWithCommas(row.recruitment_percentage, 2)} %`
    },
    {
      label: '참여 인원',
      name: 'ordered_seat',
      type: 'custom',
      width: 150,
      display: row => `${row.ordered_seat} 명`
    },
    {
      label: '모집 인원',
      name: 'total_seat',
      type: 'custom',
      width: 150,
      display: row => `${row.total_seat} 명`
    },
    {
      label: '딜 상태',
      name: 'deal_status',
      type: 'custom',
      width: 200,
      display: row => dictionary.deal_status[row.deal_status]
    },
  ]

  // "uuid": "e7ca8b4e-396e-420e-83e5-4dd2773b3c3a",
  // "type": 1,
  // "title": "공지 테스트 1의 제목",
  // "content": "공지 테스트 1의 내용",
  // "created": "2022-05-11T17:52:35.907843+09:00"

  const noticeListHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      label: 'type',
      name: 'type',
      type: 'custom',
      width: 200,
      display: row => {
        if(row.type === 1) return "클럽딜공지"
        else if(row.type === 2) return "조합공지"
        else return "정해지지않음"
      }
    },
    {
      label: '제목',
      name: 'title',
      type: 'string',
      width: 300
    },
    {
      label: '내용',
      name: 'content',
      type: 'string',
      width: 350
    },
    {
      label: '작성일',
      name: 'created',
      type: 'date',
      width: 200
    },
  ]

  // "uuid": "48755226-a333-4dcd-9505-cad4a243690a",
  // "email": "devlimebear@gmail.com",
  // "name": "김기웅",
  // "amount": "5000000.0000",
  // "price": "22.0000",
  // "estimated_number_of_shares": "0.0000",
  // "is_gp": false,
  // "created": "2021-09-10T15:36:37.512002+09:00"

  const portfolioListHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      label: '출자순서',
      name: 'invested_number',
      type: 'number',
      width: 110
    },
    {
      label: '이메일',
      name: 'email',
      type: 'string',
      width: 240
    },
    {
      label: '이름',
      name: 'name',
      type: 'strong',
      width: 150,
      onClick: row => {history.push(`/admin/user/${row.user_uuid}`)}
    },
    {
      label: '출자금액',
      name: 'amount',
      type: 'custom',
      width: 200,
      display: row => `${numberWithCommas(row.amount, 0)} 원`
    },
    {
      label: 'GP여부',
      name: 'is_gp',
      type: 'boolean',
      width: 200
    },
    {
      label: '출자일',
      name: 'created',
      type: 'date',
      width: 200
    },
  ]

  // amount: "0.0000"
  // icon: "https://staging-static.twig.money/images/company/75WRTeFHx5wU4A8z"
  // is_gp: false
  // title: "마감 테스트"

  const portfolioHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      name: 'icon',
      type: 'image',
      width: 100
    },
    {
      name: 'title',
      type: 'strong',
      width: 240
    },
    {
      name: 'amount',
      type: 'custom',
      width: 240,
      display: row => `${numberWithCommas(row.amount, 0)} 원`
    },
    {
      name: 'is_gp',
      type: 'boolean',
      width: 200
    },
  ]

  // amount: "1.0000"
  // email: "jwkim@fairsquarelab.com"
  // fee: "0.0000"
  // id: 122
  // name: "김지우"
  // order_at: "2021-09-10T17:04:01.071862+09:00"
  // order_status: "CANCELED"
  // title: "ㅇㅇ"
  // uuid: "1503110c-720f-410b-8ec0-77cc72611cd6"

  const orderHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '이메일',
      name: 'email',
      type: 'strong',
      width: 240,
      onClick: row => {history.push(`/admin/order/${row.uuid}`)}
    },
    {
      label: '이름',
      name: 'name',
      type: 'string',
      width: 60,
    },
    {
      label: '딜 이름',
      name: 'title',
      type: 'string',
      width: 240,
    },
    {
      label: '주문 상태',
      name: 'order_status',
      type: 'custom',
      width: 160,
      display: row => dictionary.order_status[row.order_status]
    },
    {
      label: '출자금액',
      name: 'amount',
      type: 'custom',
      width: 160,
      display: row => numberWithCommas(row.amount, 0)
    },
    {
      label: '수수료',
      name: 'fee',
      type: 'custom',
      width: 140,
      display: row => numberWithCommas(row.fee, 0)
    },
    {
      label: '주문일',
      name: 'order_at',
      type: 'date',
      width: 100
    }
  ]


  const likeHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '이메일',
      name: 'email',
      type: 'string',
      width: 320,
    },
    {
      label: '닉네임',
      name: 'nick_name',
      type: 'string',
      width: 120,
    },
    {
      label: '이름',
      name: 'name',
      type: 'custom',
      width: 100,
      display: row => row.profile.name
    },
    {
      label: '전화번호',
      name: 'phone_number',
      type: 'custom',
      width: 140,
      display: row => row.profile.phone_number
    },
    {
      label: '생일',
      name: 'birth_date',
      type: 'date',
      width: 140,
      display: row => row.profile.birth_date
    },
    {
      label: '알림신청일',
      name: 'created',
      type: 'date',
      width: 140
    }
  ]


  // company: 13
  // created: "2021-09-10T15:09:46.170659+09:00"
  // icon: "https://staging-static.twig.money/images/company/13/qEwShCTt"
  // kr_name: "저스트"
  // name: "Eat Just"

  const userLikeHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      name: 'id',
      type: 'string',
      width: 60
    },
    {
      label: '로고',
      name: 'icon',
      type: 'image',
      width: 100
    },
    {
      label: '회사 한글 이름',
      name: 'kr_name',
      type: 'string',
      width: 200,
    },
    {
      label: '회사 영문 이름',
      name: 'name',
      type: 'string',
      width: 200,
    },
    {
      label: '알림신청일',
      name: 'created',
      type: 'date',
      width: 140
    }
  ]

  // content: "ㅁㄴㅇㄹ"
  // created: "2021-12-22T11:06:58.246828+09:00"
  // deal: {id: 54, type: "CAR", banner: "https://staging-static.twig.money/images/deal/L7wlavKkwph023iC",…}
  // deal_id: 54
  // email: "mopsyshin@gmail.com"
  // id: 393
  // is_deleted: true
  // name: "신동주"
  // nickname: "LrRiYVI0"
  // parent_id: 392
  // title: "테스타로사 클럽딜 1호"
  // user: 3

  const commentHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      label: '유저',
      name: 'nickname',
      type: 'custom',
      width: 240,
      display: row => <div>
        <p>{row.nickname}</p>
        <p>{row.email}</p>
        <p>{row.name}</p>
      </div>
    },
    {
      label: '본문',
      name: 'content',
      type: 'string',
      width: 500,
    },
    {
      label: '작성일',
      name: 'created',
      type: 'date',
      width: 120
    },
    {
      label: '삭제됨',
      name: 'is_deleted',
      type: 'boolean',
      width: 80
    },
    {
      label: '딜 이름',
      name: 'deal',
      type: 'custom',
      width: 200,
      display: row => <a target="_blank" href={`${config.USER_CLI_URL}/deal/${row.deal.id}/comment`} rel="noreferrer">
        {row.deal.title}
      </a>
    },
  ]

  const commentPostHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      label: '유저',
      name: 'nickname',
      type: 'custom',
      width: 240,
      display: row => <div>
        <p>{row.nickname}</p>
        <p>{row.email}</p>
        <p>{row.name}</p>
      </div>
    },
    {
      label: '본문',
      name: 'content',
      type: 'string',
      width: 500,
    },
    {
      label: '작성일',
      name: 'created',
      type: 'date',
      width: 120
    },
    {
      label: '삭제됨',
      name: 'is_deleted',
      type: 'boolean',
      width: 80
    },
    {
      label: '포스트',
      name: 'post',
      type: 'custom',
      width: 200,
      display: row => <a target="_blank" href={`${config.USER_CLI_URL}/post/${row.post.id}`} rel="noreferrer">
        {row.post.sub_title.slice(0, 24)}
      </a>
    },
  ]

  // "created": "2022-01-24T15:31:09.136531+09:00",
  // "id": 30,
  // "sub_title": "BAYC는 단순한 NFT 컬렉션이 아니에요.",
  // "title": "[인사이트] 억 소리 나는 원숭이, BAYC",
  // "user": {
  //     "email": "twig-admin@twig.money",
  //     "name": "구연수",
  //     "nickname": "Twig",
  //     "uuid": "0569031a-6381-464f-b554-c1e46dd61a7c"
  // },
  // "uuid": "9047136b-7ab8-4289-bb5d-6d70e4d782a6"

  const PostHeaders = [
    {
      name: 'uuid',
      type: 'key',
    },
    {
      label: '유저',
      name: 'nickname',
      type: 'custom',
      width: 240,
      display: row => <div>
        <p>{row.user.nickname}</p>
        <p>{row.user.email}</p>
        <p>{row.user.name}</p>
      </div>
    },
    {
      label: '포스트',
      name: 'title',
      type: 'string',
      width: 300,
    },
    {
      label: '부제목',
      name: 'sub_title',
      type: 'strong',
      width: 300,
      onClick: row => {history.push(`/admin/post/${row.id}`)}
    },
    {
      label: '작성일',
      name: 'created',
      type: 'date',
      width: 120
    },
  ]

  return {
    companyHeaders,
    userHeaders,
    orderHeaders,
    dealHeaders,
    noticeListHeaders,
    portfolioListHeaders,
    portfolioHeaders,
    likeHeaders,
    commentHeaders,
    PostHeaders,
    commentPostHeaders,
    userLikeHeaders
  }
}

export default useHeader
