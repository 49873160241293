import theme from 'styles/theme'
import styled from 'styled-components'
import { useCallback, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useInit } from 'hooks'
import { numberWithCommas } from 'utils'
import dayjs from 'dayjs'

const DealParticipantsSignature = () => {
  const [ data, setData ] = useState([])
  const [ deal, setDeal ] = useState({

  })

  const params = useParams()

  const fetchParticipants = useCallback(async () => {
    const result = await axios.get(`/admin/deals/${params.uuid}/participant_autograph`)
    const deal = await axios.get(`/admin/deals/${params.uuid}`)
    setData(result.data)
    setDeal(deal.data)
  }, [params.uuid])

  useInit(fetchParticipants)

  return (
    <Style>
      { data.map(({ uuid, name, birth_date, address1, address2, zipcode, email, sign_url, amount, created }, index) => <div key={uuid} className="paper">
      <div className="user-info">
          <h2>[별지내역]</h2><br/>
          <h3>갑의 표시 - { index + 1 }</h3><br/>
          성명 : {name}<br/>
          주소 : {address1} {address2} ({zipcode})<br/>
          생년월일 : {birth_date}<br/>
          이메일 : {email}<br/>
          출자금액 : {numberWithCommas(amount)}원<br/>
          { deal.invest_type === 'PARTY' ? 
          `손익 분배율 : ${(Number(amount) / Number(deal.value) * 100).toFixed(2)}%` : 
          `지분율 : ${(Number(amount) / Number(deal.total_amount) * 100).toFixed(2)}%`}<br/><br/>
          서명 : 
        </div>
        <div className="signature">
          <img src={sign_url} alt='signature'/>
        </div>

        <div className="created-date">
          전자 서명 / { dayjs(created).format('YYYY.MM.DD HH:mm:ss') }
        </div>
      </div>)}
    </Style>
  )
}

const Style = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .paper {
    width: 690px;
    height: 1020px;
    background-color: ${theme.nl(1)};
    .user-info {
      line-height: 1.4;
      font-size: 20px;
    }
    .signature {
      width: 300px;
      border: 1px solid ${theme.nd(0.2)};
      border-radius: 16px;
    }
    .created-date {
      margin-top: 8px;
      font-size: 14px;
      color: ${theme.nd(0.4)};
    }
  }
`

export default DealParticipantsSignature
