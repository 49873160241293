import theme from 'styles/theme'
import styled from 'styled-components'
import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { numberWithCommas } from 'utils'

const StatisticsDisplayer = ({ statistics_api_url }) => {
  const [ statistics, setStatistics ] = useState({
    amount__count: 0,
    amount__sum: 0
  })

  const fetchData = useCallback(async () => {
    const result = await axios.get(statistics_api_url)
    setStatistics(result.data)
  }, [statistics_api_url])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <StatisticsStyle className='flex f-h-between f-v-center'>
      <div className="label">Total</div>
      <div className="count">{numberWithCommas(statistics.amount__count)}건</div>
      <div className="amount">{numberWithCommas(statistics.amount__sum)}원</div>
    </StatisticsStyle>
  )
}

const StatisticsStyle = styled.div`
  ${theme.card}
  font-size: 16px;
  .label {
    font-weight: 600;
    width: 80px;
  }
  .count {
    margin-right: 32px;
  }
`

export default StatisticsDisplayer
