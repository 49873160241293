import theme from 'styles/theme'

const IcPlus = ({ 
  width = 24, 
  height = 24,
  stroke = 1,
  color = theme.nd(1) }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12H21" stroke={color} strokeWidth={stroke}/>
      <path d="M12 3L12 21" stroke={color} strokeWidth={stroke}/>
    </svg>
  )
}

export default IcPlus
