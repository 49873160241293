import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import theme from 'styles/theme'

const Style = styled.div`
  .prev {
    cursor: pointer;
  }
  .next {
    transform: rotate(180deg);
    cursor: pointer;
  }
  .numbers {
    padding: 0 16px;
  }
`

const PageItem = styled.div`
  padding: 8px;
  border: 1px solid ${theme.nd(0.06)};
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;
  color: ${theme.nd(0.4)};
  ${({ active }) => active && css`
    border: 1px solid ${theme.nd(0.8)};
    color: ${theme.positive(1)};
    font-weight: 600;
  `}
`

const Pagination = ({ current = 2, length = 100, fetchData = () => {} }) => {
  const [pages, setPages] = useState([])
  
  useEffect(() => {
    if (Number(current) > Number(length)) {
      throw new Error('현재 페이지는 전체 페이지 수보다 클 수 없습니다.')
    }
    
    const startPage = current > 5 ? current - 5 : 1;
    const endPage = current + 5 > length ? length : current + 5

    let arr = []
    for (let i = startPage; i <= endPage; i ++) {
      arr.push(i)
    }
    setPages(arr)
  }, [current, length])

  return (
    <Style className="flex flex-center">
      <span className="prev">
        <img src="/assets/ic-left-arrow.svg" alt="arrow" onClick={() => {
          if ( current > 1) {
            fetchData(current - 1)
          }
        }}/>
      </span>
      <div className="numbers flex flex-center">
        {pages.map(page => (
          <PageItem className="page" 
                    active={current === page}
                    key={page}
                    onClick={() => {
                      fetchData(page)
                    }}>
            {page}
          </PageItem>
        ))}
      </div>
      <span className="next">
        <img src="/assets/ic-left-arrow.svg" alt="arrow" onClick={() => {
          if ( current < length) {
            fetchData(current + 1)
          }
        }}/>
      </span>
    </Style>
  )
}

export default Pagination