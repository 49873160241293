import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';
import StatisticsDisplayer from 'components/StatisticsDisplayer';
import OrderFilter from 'components/TableFilters/OrderFilter';

const OrderList = () => {
  const { orderHeaders } = useHeader()
  const [ orders, setOrders ] = useState([])
  const [ pageLength, setPageLength ] = useState(1)
  const [ current, setCurrent ] = useState(1)
  const [ orderStatus, setOrderStatus ] = useState("")
  const [ dealFilter, setDealFilter ] = useState({
    uuid: "",
    title: ""
  })

  const fetchData = useCallback(async (page, filter = { order_status: '', deal: '' }) => {
    if (page) {
      setCurrent(page)
    }

    const { order_status, deal } = filter

    const params = {}
    if (page) params.page = page
    if (order_status) params.order_status = order_status
    if (deal) params.deal = deal

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/orders?${queryString}`)
    setOrders(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setPageLength(len)
    } else {
      setPageLength(1)
    }
  }, [])

  const paginationHandler = async page => {
    await fetchData(page, { order_status: orderStatus, deal: dealFilter.uuid })
  }

  useInit(fetchData)

  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2>출자 관리</h2>
          <OrderFilter orderStatus={orderStatus} 
                       setOrderStatus={setOrderStatus}
                       dealFilter={dealFilter}
                       setDealFilter={setDealFilter}
                       fetchData={fetchData}/>
        </div>
        <Division height="24px"/>
        <div className="statistics-area flex">
          {dealFilter.uuid && <StatisticsDisplayer statistics_api_url={`/admin/orders/sum?deal=${dealFilter.uuid}&order_status=${orderStatus}`}/>}
        </div>
        <Division height="24px"/>
        <DataTable headers={orderHeaders} data={orders}/>
        <Division height="64px"/>
        <Pagination current={current} length={pageLength} fetchData={paginationHandler}/>
        <Division height="64px"/>
      </Style>
    </DefaultLayout>
  )
}

const Style = styled.div`
  ${theme.card}
  .statistics-area {
    justify-content: flex-end;
  }
`

export default OrderList
