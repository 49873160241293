import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { useHistory } from 'react-router-dom';
import MoreButton from 'components/button/MoreButton';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';
import { Input } from 'components/input';
import { Button } from 'components/button';

const Style = styled.div`
  ${theme.card}
`

const CompanyList = () => {
  const { companyHeaders } = useHeader()
  const history = useHistory()
  const [ companies, setCompanies ] = useState([])
  const [ pageLength, setPageLength ] = useState(1)
  const [ current, setCurrent ] = useState(1)
  const [ searchValue, setSearchValue ] = useState('')

  const fetchData = useCallback(async (page, filters = {search: ''}) => {
    if (page) {
      setCurrent(page)
    }

    const params = {}
    if (page) params.page = page
    if (filters.search) params.search = filters.search

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/companies?${queryString}`)
    setCompanies(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setPageLength(len)
    }
  }, [])

  useInit(fetchData)

  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const search = async () => {
    await fetchData(1, { search: searchValue.trim() })
  }

  const paginationHandler = async page => {
    await fetchData(page, { search: searchValue.trim() })
  }

  const onKeyPressEnter = async e => {
    if (e.key === 'Enter') {
      await search()
    }
  }

  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <div className="flex f-v-center">
            <h2>회사 정보 관리</h2>
            <Division width="8px"/>
            <MoreButton onClick={() => history.push('/admin/company/create')}/>
          </div>
          <div className="search-area">
            <Input value={searchValue}
                   width="300px"
                   onChange={onSearchChange}
                   onKeyPress={onKeyPressEnter}
                   placeholder="한글, 영문 이름 검색..."/>
            <Button primary width="80px" onClick={search}>검색</Button>
          </div>
        </div>
        <Division height="24px"/>
        <DataTable headers={companyHeaders} data={companies}/>
        <Division height="64px"/>
        <Pagination current={current} length={pageLength} fetchData={paginationHandler}/>
        <Division height="64px"/>
      </Style>
    </DefaultLayout>
  )
}

export default CompanyList
