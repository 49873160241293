import Layout from 'layout/default'
import styled from 'styled-components'
import { getToken } from 'utils/auth'
import axios from 'axios'
import { useCallback } from 'react'
import { useInit } from 'hooks'

const Style = styled.div`

`

const Home = ({ history }) => {
  const checkAuth = useCallback(async () => {
    try {
      if (getToken()) {
        const result = await axios.get("/v1/users")
        history.push('/admin/company')
        return result
      }
      history.push('/login')
    } catch (e) {
      history.push('/login')
    }
  }, [history]) 
  
  useInit(checkAuth)

  return (
    <Layout>
      <Style>
        Home
      </Style>
    </Layout>
  )
}

export default Home
