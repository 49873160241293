import axios from "axios";

export const TOKEN_KEY = 'access_token';

export const getToken = () => {
  return getCookie(TOKEN_KEY, document.cookie);
};

export const setToken = token => {
  return setCookie(TOKEN_KEY, token, 30);
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname, cookie) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const destroyToken = () => {
  setCookie(TOKEN_KEY, '', 0)
  axios.defaults.headers.common['authorization'] = ''
}
