import Home from "pages/Home";
import Login from "pages/Login";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import styled from "styled-components";
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useEffect } from "react";
import axios from "axios";
import { appInit, currentUser, isLoading } from "recoil/GlobalStore";
import LoadingBar from "components/loading-bar/LoadingBar";
import AdminHome from "pages/admin";
import CompanyDetail from "pages/admin/company/CompanyDetail";
import CompanyList from "pages/admin/company/CompanyList";
import CreateCompany from "pages/admin/company/CreateCompany";
import { getToken } from "utils/auth";
import Toast, { useToast } from "components/toast/Toast";
import config from "config/config";
import UserList from "pages/admin/user/UserList";
import UserDetail from "pages/admin/user/UserDetail";
import PopupManager from "components/popup-manager";
import DealList from "pages/admin/deal/DealList";
import DealDetail from "pages/admin/deal/DealDetail";
import OrderList from "pages/admin/order/OrderList";
import OrderDetail from "pages/admin/order/OrderDetail";
import UserLikeList from "pages/admin/user/UserLikeList";
import UserOrderList from "pages/admin/user/UserOrderList";
import SignupStats from "pages/admin/stats/SingupStats";
import DealStatsCount from "pages/admin/stats/DealStatsCount";
import DealStatsAge from "pages/admin/stats/DealStatsAge";
import CommentList from "pages/admin/comment/CommentList";
import DealParticipantsSignature from "pages/admin/stats/DealParticipantsSignature";
import PostCommentList from "pages/admin/comment/PostCommentList";
import PostDetail from "pages/admin/post/PostDetail";
import PostList from "pages/admin/post/PostList";
import CreatePost from "pages/admin/post/CreatePost";

const MainLayer = styled.div`

`

function App() {
  return (
    <RecoilRoot>
      <RecoilWrapper/>
    </RecoilRoot>
  );
}

const RecoilWrapper = () => {
  const loadingState = useRecoilValue(isLoading);

  return (<>
    <LoadingBar isLoading={loadingState}/>
    <Toast/>
    <PopupManager/>
    <Router>
      <RouterWrapper/>
    </Router>
  </>)
}

const RouterWrapper = () => {
  const setLoadingState = useSetRecoilState(isLoading)
  const [ appInitState, setAppInit ] = useRecoilState(appInit)
  const setCurrentUser = useSetRecoilState(currentUser)
  const history = useHistory()
  const { addToast } = useToast()

  const fetchMyInfo = async () => {
    const result = await axios.get('/admin/users/me')
    setCurrentUser(result.data)
  }

  useEffect(() => {
    if (!appInitState) {
      axios.defaults.baseURL = config.API_URL
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      axios.defaults.headers.common['authorization'] = `Bearer ${getToken()}`
      axios.interceptors.request.use(function (config) {
        setLoadingState(true)
        return config;
      }, function (error) {
        setLoadingState(false)
        return Promise.reject(error);
      });
      
      axios.interceptors.response.use(function (response) {
        setLoadingState(false)
        return response;
      }, function (error) {
        if (!error.response) {
          addToast('알수없는 에러가 발생했습니다', 'negative')
        } else if (error.response.status === 401) {
          alert('다시 로그인해주세요');
          history.push('/login')
        } else {
          addToast('에러가 발생했습니다', 'negative')
          console.log(error.response.data.error_class, error.response.data.targets)
        }
        setLoadingState(false)
        return Promise.reject(error);
      });
  
      setAppInit(true)  
    } else {
      fetchMyInfo()
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadingState, setAppInit, appInitState])

  return (
    <MainLayer>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/admin" component={AdminHome}/>
        <Route exact path="/admin/company" component={CompanyList}/>
        <Route exact path="/admin/company/create" component={CreateCompany}/>
        <Route exact path="/admin/company/:uuid" component={CompanyDetail}/>
        <Route exact path="/admin/comment" component={CommentList}/>
        <Route exact path="/admin/post" component={PostList}/>
        <Route exact path="/admin/post/create" component={CreatePost}/>
        <Route exact path="/admin/post/:id" component={PostDetail}/>
        <Route exact path="/admin/postcomment" component={PostCommentList}/>
        <Route exact path="/admin/deal" component={DealList}/>
        <Route exact path="/admin/deal/:uuid" component={DealDetail}/>
        <Route exact path="/admin/deal/:uuid/participant" component={DealParticipantsSignature}/>
        <Route exact path="/admin/deal/:uuid/stats/count" component={DealStatsCount}/>
        <Route exact path="/admin/deal/:uuid/stats/age" component={DealStatsAge}/>
        <Route exact path="/admin/order" component={OrderList}/>
        <Route exact path="/admin/order/:uuid" component={OrderDetail}/>
        <Route exact path="/admin/user" component={UserList}/>
        <Route exact path="/admin/user/:uuid" component={UserDetail}/>
        <Route exact path="/admin/user/:uuid/orders" component={UserOrderList}/>
        <Route exact path="/admin/user/:uuid/likes" component={UserLikeList}/>
        <Route exact path="/admin/user/stats/signup" component={SignupStats}/>
      </Switch>
    </MainLayer>
  )
}

export default App;
