import DefaultLayout from "layout/default"
import { useCallback, useState } from "react"
import styled from "styled-components"
import theme from "styles/theme"
import axios from "axios"
import { useParams } from 'react-router-dom'
import { useInit } from "hooks"
import KeyValueLabel from 'components/label/KeyValueLabel'
import { formatDate } from "utils"
import { numberWithCommas } from 'utils';
import { Division } from "components"
import DataTable from "components/table/DataTable"
import useHeader from "hooks/useHeader"
import usePopup from "hooks/usePopup"
import { Button } from "components/button"
import { useHistory } from 'react-router-dom';
import xlsx from "json-as-xlsx"
import { BooleanLabel } from "components/label/BooleanLabel"

const Style = styled.div`
  ${theme.card};
  .board {
    padding: 16px 0;
    display: flex;
    .column {
      width: 50%;
      & > * {
        margin: 16px 0;
      }
      h2 {
        margin-bottom: 24px;
      }
      .label {
        font-weight: 400;
        color: ${theme.nd(0.5)};
        width: 200px;
        margin-right: 16px;
      }
      .icon {
        width: 30%
      }
      .banner {
        width: 70%
      }
    }
  }
  .actions {
    & > * {
      margin-right: 16px;
    }
  }
  .list {
    .title {
      margin-bottom: 24px;
    }
    .more {
      margin-left: 8px;
    }
  }
`


const DealDetail = () => {
  const params = useParams()
  const history = useHistory()
  const { confirm } = usePopup()
  const { noticeListHeaders, portfolioListHeaders, likeHeaders } = useHeader()
  const [ data, setData ] = useState({
    uuid: "", // uuid
  })

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/deals/${params.uuid}`)
    setData(result.data)
  }, [params.uuid])

  const moveCountStats = () => {
    history.push(`/admin/deal/${params.uuid}/stats/count`)
  }

  const moveAgeStats = () => {
    history.push(`/admin/deal/${params.uuid}/stats/age`)
  }

  const exportXlsx = source => {
    const columns = Object.keys(source[0]).map(key => {
      return {
        label: key,
        value: key
      };
    });

    const data = [
      {
        sheet: 'datas',
        columns: columns,
        content: source
      }
    ]

    const settings = {
      fileName: 'datas'
    }

    xlsx(data, settings)
  }

  const exportParticipant = () => {
    confirm(<>참여자 목록을 다운로드 받겠습니까?</>, {
      title: 'CSV Export',
      onConfirm: async () => {
        const result = await axios.get(`/admin/deals/${params.uuid}/participant`)
        exportXlsx(result.data)
      }
    })
  }

  const printParticipantSignature = () => {
    history.push(`/admin/deal/${params.uuid}/participant`)
  }

  useInit(fetchData)

  return (
    <DefaultLayout>
      <Style>
        <div className="actions flex f-h-end">
          <Button secondary onClick={moveCountStats}>출자 건수 통계</Button>
          <Button secondary onClick={moveAgeStats}>출자 나이 통계</Button>
          <Button secondary onClick={exportParticipant}>참여자 엑셀 다운로드</Button>
          <Button secondary onClick={printParticipantSignature}>출자자 명단 출력</Button>
        </div>
        <div className="board">
          <div className="column">
            <h2>시스템 정보</h2>
            <KeyValueLabel label="UUID" info={data.uuid}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>클럽딜 기본 정보</h2>
            <KeyValueLabel label="클럽딜 제목" info={data.title}/>
            <KeyValueLabel label="클럽딜 상태" info={data.deal_status}/>
            <KeyValueLabel label="클럽딜 진행 상태" info={data.deal_progress}/>
            <KeyValueLabel label="Deal status reason" info={data.deal_status_reason}/>
            <KeyValueLabel label="original_currency" info={data.original_currency}/>
            <KeyValueLabel label="원화 가치" info={`${numberWithCommas(data.value)}`}/>
            <KeyValueLabel label="통화 가치" info={`${numberWithCommas(data.original_value, 0)}`}/>
          </div>
          <div className="column">
            <div className="label">ICON</div>
            <img className="icon" src={`${(data.icon)}`} alt="icon"/>
            <div className="label">BANNER</div>
            <img className="banner" src={`${(data.banner)}`} alt="banner" />
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>클럽딜 주문 단위</h2>
            <KeyValueLabel label="최소 금액 금액" info={`${numberWithCommas(data.min_order_amount, 0)} 원`}/>
            <KeyValueLabel label="최대 주문 금액" info={`${numberWithCommas(data.max_order_amount, 0)} 원`}/>
            <KeyValueLabel label="출자 단위" info={`${numberWithCommas(data.unit_order_amount, 0)} 원`}/>
            <KeyValueLabel label="수수료" info={`${numberWithCommas(data.fee, 0)} 원`}/>
            <KeyValueLabel label="Carried interest" info={data.carried_interest}/>
          </div>
          <div className="column">
            <h2>클럽딜 시간 정보</h2>
            <KeyValueLabel label="클럽딜 시작 시간" info={formatDate(data.start_at)}/>
            <KeyValueLabel label="클럽딜 마감 시간" info={formatDate(data.ended_at)}/>
            <KeyValueLabel label="클럽딜 종료 시간" info={formatDate(data.finished_at)}/>
            <KeyValueLabel label="Completed buying at" info={formatDate(data.completed_buying_at)}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>클럽딜 판매 현황</h2>
            <KeyValueLabel label="총 판매량" info={`${numberWithCommas(data.total_amount, 0)} 원`}/>
            <KeyValueLabel label="현재 판매량" info={`${numberWithCommas(data.ordered_amount, 0)} 원`}/>
            <KeyValueLabel label="남은 판매량" info={`${numberWithCommas(data.left_amount, 0)} 원`}/>
          </div>
          <div className="column">
            <h2>클럽딜 판매 좌수</h2>
            <KeyValueLabel label="총 좌석" info={`${numberWithCommas(data.total_seat, 0)} 좌석`}/>
            <KeyValueLabel label="현재 좌석" info={`${numberWithCommas(data.ordered_seat, 0)} 좌석`}/>
            <KeyValueLabel label="남은 좌석" info={`${numberWithCommas(data.left_seat, 0)} 좌석`}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>Company(자산)</h2>
            <KeyValueLabel label="Type" info={`${data.type}`}/>
            <KeyValueLabel label="Invest type" info={`${data.invest_type}`}/>
            <KeyValueLabel label="Company(자산)" info={`${data.name}`}/>
            <KeyValueLabel label="소개" info={`${data.introduction}`}/>
            <KeyValueLabel label="url" info={`${data.url}`}/>
          </div>
          <div className="column">
            <h2>PrimeDeal</h2>
            <KeyValueLabel label="Is prime deal" info={<BooleanLabel status={data.is_prime_deal}/>}/>
            <KeyValueLabel label="Prime limit amount" info={`${numberWithCommas(data.prime_limit_amount, 0)}`}/>
            <KeyValueLabel label="Prime limit count " info={`${numberWithCommas(data.prime_limit_count, 0)}`}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>Stock Price</h2>
            <KeyValueLabel label="Stock price" info={`${numberWithCommas(data.stock_price, 0)} 원`}/>
            <KeyValueLabel label="Stock price krw" info={`${numberWithCommas(data.stock_price_krw, 0)} 원`}/>
            <KeyValueLabel label="조합 구좌당가격" info={`${numberWithCommas(data.price_per_share, 0)} 원`}/>
          </div>
          <div className="column">
            <h2>Union</h2>
            <KeyValueLabel label="Union" info={data.union}/>
            <KeyValueLabel label="Partner" info={data.partner}/>
            <KeyValueLabel label="Invested amount" info={`${numberWithCommas(data.invested_amount, 0)} `}/>
            <KeyValueLabel label="Number of shares" info={`${numberWithCommas(data.number_of_shares, 0)} `}/>
          </div>
        </div>
      <Division height="64px"/>
      <div className="list">
        <div className="title flex f-v-center">
          <h2>공지</h2>
        </div>
        <DataTable headers={noticeListHeaders} data={data.notices}/>
      </div>
      <Division height="64px"/>
      <div className="list">
        <div className="title flex f-v-center">
          <h2>참여자 목록</h2>
        </div>
        <DataTable headers={portfolioListHeaders} data={data.portfolios}/>
      </div>

      <Division height="64px"/>

      <div className="list">
        <div className="title flex f-v-center">
          <h2>알림신청 내역 ({data.likes ? data.likes.length : 0} 건)</h2>
        </div>
        <DataTable headers={likeHeaders} data={data.likes}/>
      </div>
      </Style>
    </DefaultLayout>
  )
}

export default DealDetail
