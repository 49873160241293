import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default'
import { useInput } from 'hooks'
import axios from 'axios'
import { Division } from 'components'
import { Button } from 'components/button'
import { useHistory } from 'react-router-dom'
import { useToast } from 'components/toast/Toast'
import CompanyTemplate from 'components/template/CompanyTemplate'

const Style = styled.div`
  ${theme.card};
  .grid-board {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
  .row {
    margin: 24px 0;
    .label {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .new-tag {
  }
  .tag-list {
    ${theme.card}
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin: 4px;
    }
  }
  .submit-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
`

const CreateCompany = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [ state, onChange ] = useInput({
    id: null,
    name: '',
    kr_name: '',
    url: '',
    established: '',
    introduction: '',
    detail: '',
    investors: '',
    is_active: false,
    icon: '',
    icon_preview: '',
    banner: '',
    banner_preview: '',
    twitter: '',
    tags: [],
    newTag: '',
  })

  const createCompany = async () => {
    if (state.icon === "") return addToast('아이콘을 등록해주세요', 'negative')

    if (state.banner === "") return addToast('배너를 등록해주세요', 'negative')

    const params = { ...state }
    delete params.newTag

    const result = await axios.post('/admin/companies', params)

    addToast('회사정보 등록 성공')
    history.push(`/admin/company/${result.data.id}`)
  }

  return (
    <DefaultLayout>
      <Style>
        <h2>회사 정보 추가</h2>
        <CompanyTemplate state={state} onChange={onChange}/>

        <div className="submit-action">
          <Button primary width="200px" onClick={createCompany}>회사정보 저장</Button>
        </div>

        <Division height="48px"/>
      </Style>
    </DefaultLayout>
  )
}

export default CreateCompany
