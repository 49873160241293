import theme from 'styles/theme'
import styled from 'styled-components'
import ListItem from './ListItem'

const Style = styled.div`
  width: 100%;
  .header {
    display: flex;
    padding: 16px;
    color: ${theme.nd(0.4)};
  }
  .body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
`

const HeaderItem = styled.div`
  text-align: left;
  width: ${({ width }) => `${width}px` || 'auto'};
  &.key {
    display: none;
  }
`

const DataTable = ({ headers = [], data = [] }) => {
  return (
    <Style>
      <div className="header">
        {headers.map(header => <HeaderItem key={header.name} width={header.width} className={header.type}>{header.label || header.name}</HeaderItem>)}
      </div>
      <div className="body">
        {data.map(row => <ListItem key={row.id || row.uuid} row={row} headers={headers}/>)}
      </div>
    </Style>
  )
}

export default DataTable
