import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';

const Style = styled.div`
  ${theme.card}
`

const PostCommentList = () => {
    const { commentPostHeaders } = useHeader()
    const [ comments, setComments ] = useState([])
    const [ pageLength, setPageLength ] = useState(1)
    const [ current, setCurrent ] = useState(1)
    const fetchData = useCallback(async (page, filter = {}) => {
        if (page) {
            setCurrent(page)
        }

        const params = {}
        if (page) params.page = page

        const queryString = new URLSearchParams(params).toString()

        const result = await axios.get(`/admin/post_comments?${queryString}`)
        setComments(result.data.results)
        if (result.data.count) {
            const len = result.data.count / 20 + 1
            setPageLength(len)
        } else {
            setPageLength(1)
        }
    }, [])

    const paginationHandler = async page => {
        await fetchData(page, {})
    }

    useInit(fetchData)

    return (
        <DefaultLayout>
            <Style>
                <div className="flex f-v-center f-h-between">
                    <h2>POST 댓글 관리</h2>
                    <div className="filters flex f-v-center">
                    </div>
                </div>
                <Division height="24px"/>
                <DataTable headers={commentPostHeaders} data={comments}/>
                <Division height="64px"/>
                {<Pagination current={current} length={pageLength} fetchData={paginationHandler}/>}
                <Division height="64px"/>
            </Style>
        </DefaultLayout>
    )
}

export default PostCommentList
