import theme from 'styles/theme'
import styled from 'styled-components'
import { Input, Select, SwitchBox, TextArea } from 'components/input'
import UploadButton from 'components/button/UploadButton'
import config from 'config/config'

const Style = styled.div`
  .guide-link {
    display: block;
    padding: 16px 24px;
    background-color: ${theme.positive(0.05)};
    color: ${theme.positive(1)};
    border: 1px solid ${theme.positive(1)};
    margin: 16px 0;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
  }
  .grid-board {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
  .row {
    margin: 24px 0;
    .label {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .new-tag {
  }
  .tag-list {
    ${theme.card}
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin: 4px;
    }
  }
  .selected-tag-item {
    display: flex;
    align-items: center;
    .remove-button {
      margin-left: 4px;
      width: 16px;
      height: 16px;
      background-color: ${theme.nd(0.6)};
      transform: rotate(45deg);
      border-radius: 16px;
      cursor: pointer;
    }
  }
`

const PostTemplate = ({ state, onChange }) => {
  return (
    <Style>
      <a className="guide-link" href={config.COMPANY_UPLOAD_GUIDE} target="_blank" rel="noreferrer">
        📜  POST 작성 가이드
      </a>
      <div className="grid-board">
          <div className="board-section">

            <div className="row">
              <div className="label">
                POST 제목
              </div>
              <Input  name="title"
                      width="343px"
                      value={state.title}
                      onChange={onChange}
                      placeholder="POST 제목"/>
            </div>

            <div className="row">
              <div className="label">
                POST 부제목
              </div>
              <Input  name="sub_title"
                      width="343px"
                      value={state.sub_title}
                      onChange={onChange}
                      placeholder="POST 부제목"/>
            </div>

            {/* <div className="row">
              <div className="label">
                Og json
              </div>
              <Input name="Og_json"
                    width="343px"
                    value={state.og_json}
                    onChange={onChange}
                    placeholder="{}"/>
            </div> */}

            <div className="row">
              <div className="label">
                Category
              </div>
              <Select name="category"
                      width="343px"
                      value={state.category}
                      onChange={onChange}
                      placeholder="category"
                      multiple={false}>
                        <option value="TALK">talk</option>
                        <option value="EVENT">event</option>
                        <option value="MAGAZINE">magazine</option>
                        <option value="GROUP">group</option>
              </Select>
            </div>

            <div className="row">
              <div className="label">
                Type
              </div>
              <Select name="type"
                      width="343px"
                      value={state.type}
                      onChange={onChange}
                      placeholder="type"
                      multiple={false}>
                        <option value="DEFAULT" >default</option>
                        <option value="EDIT" >edit</option>
                        <option value="WEB" >web</option>
              </Select>
            </div>

            <div className="row">
              <div className="label">
                활성
              </div>
              <SwitchBox value={state.is_active}
                         onChange={e => {
                           const params = {
                             target: {
                               value: e.target.checked,
                               name: e.target.name
                             }
                           }
                           onChange(params)
                          }}
                         name="is_active"/>
            </div>

            <div className="row">
              <div className="label">
                삭제
              </div>
              <SwitchBox value={state.is_deleted}
                         onChange={e => {
                           const params = {
                             target: {
                               value: e.target.checked,
                               name: e.target.name
                             }
                           }
                           onChange(params)
                          }}
                         name="is_deleted"/>
            </div>

          </div>

          <div className="board-section">

            <div className="row">
              <div className="label">배너 (515X301)</div>
              <UploadButton url={state.banner}
                            onChange={onChange}
                            id="post-banner"
                            name="banner"
                            width="428px"
                            height="250px"
                            type="posts" />
            </div>

            <div className="row">
              <div className="label" >
                POST 내용
              </div>
              <TextArea name="body"
                    width="343px"
                    rows="10"
                    value={state.body}
                    onChange={onChange}
                    placeholder="POST 내용 입력" />

            </div>

          </div>
        </div>
    </Style>
  )
}

export default PostTemplate
