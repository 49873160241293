import styled from 'styled-components'
import Gnb from 'components/gnb/Gnb'
import Sidebar from 'components/sidebar/Sidebar'

const Style = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .body-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    .board-wrapper {
      width: calc(100% - 216px);
      padding-bottom: 120px;
    }
  }
`

const DefaultLayout = ({ children }) => {

  return (
    <Style>
      <Gnb/>
      <div className="body-wrapper">
        <Sidebar/>
        <div className="board-wrapper">
          { children }
        </div>
      </div>
    </Style>
  )
}

export default DefaultLayout
