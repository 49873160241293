import styled from 'styled-components'

export const Division = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '8px'};
  background-color: ${props => props.bg || 'transparent'};
  line-height: 1.4;
  word-wrap: wrap;
  word-break: keep-all;
`;

export const EmptyComponent = () => <></>