import dayjs from 'dayjs'

export const getExt = filename => {
 
  const nameLength = filename.length;
  const lastDot = filename.lastIndexOf('.');
  const ext = filename.substring(lastDot, nameLength).toLowerCase();

  const fileName = filename.substring(0, lastDot);

  return { fileName, ext };
}

export const keyUpEnter = (e, callback) => {
  if (e.key === 'Enter' && e.target.value !== '') {
    callback(e)
    return
  }
}

export const getNumberUnit = (labelValue, fixed = 2) => {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(fixed) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(fixed) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3

  ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(fixed) + "K"

  : Math.abs(Number(labelValue));

}

export const numberWithCommas = (x, fixed) => {
  if (!x) return '0'
  const str = Number(x).toFixed(fixed || 0).toString()
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = date => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}