import styled from 'styled-components'
import theme from 'styles/theme'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { toastCount, toastList } from 'recoil/GlobalStore'

const Style = styled.div`
  z-index: 20000;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const ItemStyle = styled.div`
  margin: 4px auto;
  padding: 12px 24px;
  border-radius: 24px;
  box-shadow: 2px 4px 16px ${theme.nd(0.16)};
  animation: ToastItem 3s forwards;
  z-index: 20000;
  font-size: 14px;
  text-align: center;
  @media (min-width: 860px) {
    padding: 16px;
  }
  &.normal {
    background-color: ${theme.nl(1)};
    color: ${theme.nd(0.6)};
  }
  &.positive {
    background-color: ${theme.positive(1)};
    color: ${theme.nl(1)};
  }
  &.negative {
    background-color: ${theme.negative(1)};
    color: ${theme.nl(1)};
  }
  &.alert {
    background-color: ${theme.alert(1)};
    color: ${theme.nl(1)};
  }
  @keyframes ToastItem {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    10% {
      transform: translateY(-40px);
      opacity: 1;
    }
    90% {
      transform: translateY(-40px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
`

const ToastItem = ({id, className, children}) => {
  return (
    <ItemStyle className={className}>
      { children }
    </ItemStyle>
  )
}

const Toast = () => {
  const toasts = useRecoilValue(toastList)
  
  return (
    <Style count={toasts.length}>
      {
        toasts.map((item) => (
          <ToastItem key={item.id} id={item.id} className={item.theme}>
            { item.message }
          </ToastItem>
        ))
      }
    </Style>
  )
}

export const useToast = () => {
  const setToasts = useSetRecoilState(toastList)
  const [count, setCount] = useRecoilState(toastCount)

  const removeToast = id => {
    setToasts(prev => prev.reduce((acc, value) => {
      if (value.id !== id) {
        acc.push(value)
      }
      return acc
    }, []))
  }

  const addToast = (message, type) => {
    const newToast = {
      id: count,
      message,
      theme: type || 'positive',
    }

    setToasts(prev => [
      ...prev,
      newToast
    ])
    
    setCount(prev => prev + 1)

    setTimeout(() => {
      removeToast(newToast.id)
    }, 3000)
  }

  return { addToast, removeToast }
}

export default Toast;
