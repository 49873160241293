import { atom } from 'recoil'

export const isLoading = atom({
  key: 'isLoading',
  default: false,
});

export const appInit = atom({
  key: 'appInit',
  default: false,
})

export const toastList = atom({
  key: 'toastList',
  default: []
})

export const toastCount = atom({
  key: 'toastCount',
  default: 0
})

export const popupList = atom({
  key: 'popupList',
  default: []
})

export const popupIdCount = atom({
  key: 'popupIdCount',
  default: 0
})

export const currentUser = atom({
  key: 'currentUser',
  default: {
    email: "",
    staff_grade: 0,
    uuid: ""
  }
})