import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';
import { Button } from 'components/button';
import { Input } from 'components/input';

const Style = styled.div`
  ${theme.card}
  .filters {
    gap: 8px;
    .by-status {
      select {
        border: 1px solid ${theme.nd(0.2)};
        border-radius: 4px;
        height: 48px;
        padding: 0 8px;
      }
    }
    .by-deal {
      position: relative;
      .deal-filter-button {
        border: 1px solid ${theme.nd(0.2)};
        border-radius: 4px;
        height: 48px;
        padding: 0 16px;
        text-align: left;
        min-width: 160px;
      }
      .deal-filter {
        ${theme.card}
        position: absolute;
        transform: translateX(-50%);
        padding: 16px;
        .deal-list {
          width: 320px;
          .deal-item {
            cursor: pointer;
            padding: 8px;
            border: 1px solid ${theme.nd(0.08)};
            background-color: ${theme.nd(0.00)};
            border-radius: 4px;
            margin: 8px 0;
            transition: all 0.3s;
            &:hover {
              background-color: ${theme.nd(0.04)};
            }
          }
        } 
      }
    }
  }
`

const DealList = () => {
  const { dealHeaders } = useHeader()
  const [ deals, setDeals ] = useState([])
  const [ pageLength, setPageLength ] = useState(1)
  const [ current, setCurrent ] = useState(1)
  const [ searchValue, setSearchValue ] = useState('')

  const fetchData = useCallback(async (page, filters = { order_status: '', deal: '' }) => {
    if (page) {
      setCurrent(page)
    }

    const params = {}
    if (page) params.page = page
    if (filters.search) params.search = filters.search

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/deals?${queryString}`)
    setDeals(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setPageLength(len)
    } else {
      setPageLength(1)
    }
  }, [])

  useInit(fetchData)

  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const search = async () => {
    await fetchData(1, { search: searchValue.trim() })
  }

  const paginationHandler = async page => {
    await fetchData(page, { search: searchValue.trim() })
  }

  const onKeyPressEnter = async e => {
    if (e.key === 'Enter') {
      await search()
    }
  }

  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2>클럽딜 관리</h2>
          <div className="search-area">
            <Input value={searchValue}
                   width="300px"
                   onChange={onSearchChange}
                   onKeyPress={onKeyPressEnter}
                   placeholder="클럽딜 제목 검색..."/>
            <Button primary width="80px" onClick={search}>검색</Button>
          </div>
        </div>
        <Division height="24px"/>
        <DataTable headers={dealHeaders} data={deals}/>
        <Division height="64px"/>
        <Pagination current={current} length={pageLength} fetchData={paginationHandler}/>
        <Division height="64px"/>
      </Style>
    </DefaultLayout>
  )
}

export default DealList
