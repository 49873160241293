import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useInit } from 'hooks';
import { Division } from 'components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Style = styled.div`
  ${theme.card}
  .search-area {
    display: flex;
    gap: 8px;
  }
`

const SignupStats = () => {
  const [state, setState] = useState({
    labels: [], 
    datasets: [
      {
        label: 'signup_total',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        data: []
      }
    ]
  })
  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/report/users/signup`)
    setState({
      labels: result.data.date, 
      datasets: [
        {
          label: 'signup_total',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          data: result.data.signup_total
        }
      ]
    })
  }, [])

  useInit(fetchData)



  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2>가입 통계</h2>
        </div>
        <Division height="24px"/>
        <Bar
          data={state}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            ticks: {
              stepSize: 5
            }
          }}
        />
      </Style>
    </DefaultLayout>
  )
}

export default SignupStats
