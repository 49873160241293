import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default'
import { useInit, useInput } from 'hooks'
import { useCallback } from 'react'
import axios from 'axios'
import { Button } from 'components/button'
import { useParams } from 'react-router-dom'
import { useToast } from 'components/toast/Toast'
import PostTemplate from 'components/template/PostTemplate'
import KeyValueLabel from 'components/label/KeyValueLabel'
import { formatDate } from 'utils'

const Style = styled.div`
  ${theme.card};
  .grid-list {
    display: grid;
    padding: 16px 0;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  .submit-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .board {
    padding: 16px 0;
    display: flex;
    .column {
      width: 50%;
      & > * {
        margin: 16px 0;
      }
      h2 {
        margin-bottom: 24px;
      }
    }
  }
`

const PostDetail = () => {
  const params = useParams()
  const { addToast } = useToast()
  const [ state, onChange ] = useInput({
    id: null,
	  uuid: null,
	  title: '',
  	sub_title:'',
  	category: '',
	  type: '',
  	body: '',
    is_active: true,
    is_deleted: false,
    banner: '',
    created: '', 
    // og_json: {},
    user: {},
  })

  const setData = result => {
    for (let key in result.data) {
      onChange({
        target: {
          name: key,
          value: result.data[key]
        }
      })
    }
  }

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/posts/${params.id}`)
    setData(result)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useInit(fetchData)

  const savePost = async () => {
    // if (state.banner === "") return addToast('배너를 등록해주세요', 'negative')
    const params = { ...state }
    await axios.patch(`/admin/posts/${state.id}`, params)
    addToast('POST 저장 성공')
  }

  return (
    <DefaultLayout>
      <Style>
        <h2>POST 수정</h2>
        <PostTemplate state={state} onChange={onChange}/>
        
        <div className="submit-action">
          <Button primary width="200px" onClick={savePost}>POST 저장</Button>
        </div>

        <div className="board">
          <div className="column">
            <h2>POST 기본 정보</h2>
            <KeyValueLabel label="작성자 이름" info={state.user.name}/>
            <KeyValueLabel label="작성자 email" info={state.user.email}/>
            <KeyValueLabel label="작성일" info={formatDate(state.created)}/>
          </div>
        </div>
      </Style>
    </DefaultLayout>
  )
}

export default PostDetail
