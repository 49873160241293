import styled from "styled-components"
import theme from "styles/theme"

const IconLabel = styled.img`
  border-radius: 32px;
  width: 64px;
  height: 64px;
  border: 1px solid ${theme.nd(0.1)};
`

export default IconLabel
