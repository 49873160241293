import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default'
import { useInput } from 'hooks'
import axios from 'axios'
import { Division } from 'components'
import { Button } from 'components/button'
import { useHistory } from 'react-router-dom'
import { useToast } from 'components/toast/Toast'
import PostTemplate from 'components/template/PostTemplate'

const Style = styled.div`
  ${theme.card};
  .grid-board {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
  .row {
    margin: 24px 0;
    .label {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .new-tag {
  }
  .tag-list {
    ${theme.card}
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin: 4px;
    }
  }
  .submit-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
`

const CreatePost = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [ state, onChange ] = useInput({
    id: null,
	  uuid: null,
	  title: '',
  	sub_title:'',
  	category: 'TALK',
	  type: 'DEFAULT',
  	body: '',
    is_active: true,
    is_deleted: false,
    banner: '',
    created: '', 
    // og_json: {},
    user: {},
  })
  
  const createPost= async () => {
    // if (state.banner === "") return addToast('배너를 등록해주세요', 'negative')

    const params = { ...state }

    const result = await axios.post('/admin/posts',params)

    addToast('포스트 등록 성공')
    history.push(`/admin/post/${result.data.id}`)
  }

  return (
    <DefaultLayout>
      <Style>
        <h2>POST 추가</h2>
        <PostTemplate state={state} onChange={onChange}/>

        <div className="submit-action">
          <Button primary width="200px" onClick={createPost}>POST 저장</Button>
        </div>

        <Division height="48px"/>
      </Style>
    </DefaultLayout>
  )
}

export default CreatePost
