import theme from 'styles/theme'
import styled from 'styled-components'
import { Input, TextArea } from 'components/input'
import IcMinus from 'svg/IcMinus'
import UploadButton from 'components/button/UploadButton'
import { memo } from 'react'
import { useCompanyRelation } from 'hooks'

const Style = styled.div`
  ${theme.card};
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 100px 1fr 3fr 48px;
  column-gap: 8px;
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .remove-button {
    background-color: ${theme.negative(1)};
    border-radius: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .number-with-unit {
    padding-top: 4px;
    color: ${theme.nd(0.4)};
  }
`

const CompetitorTemplate = ({ state, setArray }) => {

  const { onChange, removeValue } = useCompanyRelation({ 
    state,
    setArray
  })
  
  return (
    <Style>
      <UploadButton 
        onChange={onChange}
        width="84px"
        height="84px"
        id={`competitor-${state.innerId}-icon`}
        url={state.icon}
        name="icon"/>
      <div className="flex flex-center">
        <Input
          name="name"
          value={state.name}
          placeholder="이름"
          onChange={onChange}/>
      </div>
      <div className="flex flex-center">
        <TextArea
          name="introduction"
          value={state.introduction}
          placeholder="소개"
          onChange={onChange}/>
      </div>
      <div className="actions">
        <div className="remove-button" onClick={removeValue}>
          <IcMinus width={16} height={16} color={theme.nl(1)} stroke={2}/>
        </div>
      </div>
    </Style>
  )
}

export default memo(CompetitorTemplate)
