import theme from 'styles/theme'
import styled from 'styled-components'
import { Input } from 'components/input'
import { useInput } from 'hooks'
import { Division } from 'components'
import { Button } from 'components/button'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { setToken } from 'utils/auth'
import { keyUpEnter } from 'utils'
import { currentUser } from 'recoil/GlobalStore'
import { useSetRecoilState } from 'recoil';

const Style = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-card {
    ${theme.card}
    padding: 32px 24px;
    width: 100%;
    max-width: 400px;
    .logo {
      width: 48px;
    }
  }
`

const Login = () => {
  const history = useHistory()
  const setCurrentUser = useSetRecoilState(currentUser)
  const [ state, onChange ] = useInput({
    email: '',
    password: '',
  })

  const loginSubmit = async () => {
    const params = {
      email: state.email,
      password: state.password
    }
    const result = await axios.post('/admin/auth/login', params, {
      headers: {
        "Content-Type": 'application/json',
        "authorization": '',
      }
    })
    axios.defaults.headers.common['authorization'] = `Bearer ${result.data.access_token}`
    setToken(result.data.access_token)
    setCurrentUser(result.data)
    history.push('/admin/company')
  }

  return (
    <Style>
      <div className="login-card">
        <div className="flex f-v-center">
          <img className="logo" src="/assets/twig-logo.svg" alt="twig logo"/>
          <Division width="8px" height="100%"/>
          <span>CMS</span>
        </div>
        <Division height="16px"/>
        <Input type="email"
               placeholder="Email"
               value={state.email}
               name="email"
               onChange={onChange}
               onKeyUp={e => keyUpEnter(e, loginSubmit)}/>
        <Division height="8px"/>
        <Input type="password"
               placeholder="Password"
               value={state.password}
               name="password"
               onChange={onChange}
               onKeyUp={e => keyUpEnter(e, loginSubmit)}/>
        <Division height="24px"/>
        <Button primary width="100%" onClick={loginSubmit}>Login</Button>
      </div>
    </Style>
  )
}

export default Login
