import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import theme from 'styles/theme'

const ButtonStyle = styled.button`
  display: inline-block;
  border: none;
  font-size: ${props => props.fontSize};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '48px'};
  padding: ${props => props.padding || '0px 16px'};
  margin: ${props => props.margin};
  border-radius: 4px;
  background-color: ${theme.nd(0.06)};
  color: ${theme.nd(1)};
  .ic-notification {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  ${({ size }) => size === 'small' && css`
    width: auto;
    height: auto;
    padding: 8px 24px;
    font-size: 12px;
  `};
  ${({ primary }) => primary && css`
    background-color: ${theme.primary};
    color: ${theme.nl(1)};
    font-weight: 600;
  `}
  ${({ secondary }) => secondary && css`
    background-color: ${theme.positive(0.06)};
    color: ${theme.positive(0.8)};
  `}
  ${({ negative }) => negative && css`
    background-color: transparent;
    color: ${theme.negative(1)};
    border: 1px solid ${theme.negative(1)};
  `}
  &:disabled {
    background-color: ${theme.nsd(0.1)};
    color: ${theme.nd(0.4)};
    font-size: 14px;
    cursor: not-allowed;
  }
  &.popup {
    width: auto;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px;
    transition: all 0.2s;
    color: ${theme.positive(1)};
    &.secondary {
      color: ${theme.gray3};
    }
    &:hover {
      background-color: ${theme.nd(0.04)};
    }
  }
`

const ButtonComp = props => {
  return (
    <ButtonStyle {...props}>
      {props.children}
    </ButtonStyle>
  )
}

export const BoarderButton = styled(ButtonComp)`
  padding: 4px 8px;
  width: auto;
  height: auto;
  background-color: transparent;
  color: ${theme.nd(1)};
  border: 1px solid ${theme.nd(1)};
  border-radius: 2px;
`

const TwigButtonComp = styled(ButtonComp)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.twig};
  color: ${theme.nd(1)};
  width: calc(100% - 48px);
  margin: 0 auto;
  height: 54px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0 2px 24px ${theme.nd(0.6)};
`

export const TwigButton = memo(TwigButtonComp)

export const Button = memo(ButtonComp)