const config = {
  S3_BUCKET_URL: 'https://staging-static.twig.money',
  API_URL: '',
  COMPANY_UPLOAD_GUIDE: "https://www.notion.so/fairsquarelab/842143c43c4a43bcbd8ebc48ed438369",
  USER_CLI_URL: '',
}

if (process.env.REACT_APP_ENV === 'production') {
  config.API_URL = 'https://api.twig.money/api'
  config.USER_CLI_URL = 'https://twig.money'
} else {
  config.API_URL = 'https://staging-api.twig.money/api'
  config.USER_CLI_URL = 'https://staging.twig.money'
}

export default config
