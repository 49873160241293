const BANK_CODE = {
  "103": "SBI저축은행",
  "002": "산업은행",
  "003": "기업은행",
  "004": "국민은행",
  "007": "수협",
  "011": "농협중앙회",
  "020": "우리은행",
  "023": "SC제일은행",
  "027": "씨티은행",
  "031": "대구은행",
  "032": "부산은행",
  "034": "광주은행",
  "035": "제주은행",
  "037": "전북은행",
  "039": "경남은행",
  "045": "새마을금고",
  "048": "신협",
  "071": "우체국",
  "081": "하나은행",
  "088": "신한은행",
  "089": "케이뱅크",
  "090": "카카오뱅크",
  "243": "한국투자증권",
  "264": "키움증권",
  "247": "NH투자증권",
  "218": "KB증권",
  "240": "삼성증권",
  "270": "하나금융투자",
  "238": "미래에셋증권",
  "278": "신한금융투자",
  "288": "카카오페이증권",
  "271": "토스증권",
  "225": "IBK투자증권",
  "209": "유안타증권",
  "224": "BNK투자증권",
  "227": "KTB투자증권",
  "261": "교보증권",
  "262": "하이투자증권",
  "263": "현대차증권",
  "265": "이베스트투자증권",
  "266": "SK증권",
  "267": "대신증권",
  "268": "아이엠투자증권",
  "269": "한화증권",
  "279": "DB금융투자",
  "280": "유진투자증권",
  "287": "메리츠증권",
  "290": "부국증권",
  "291": "신영증권",
  "292": "케이프투자증권",
  "294": "한국포스증권",
}

export default BANK_CODE
