import DefaultLayout from "layout/default"
import { useCallback, useState } from "react"
import styled from "styled-components"
import theme from "styles/theme"
import axios from "axios"
import { useParams } from 'react-router-dom'
import { useInit } from "hooks"
import KeyValueLabel from 'components/label/KeyValueLabel'
import { formatDate, numberWithCommas } from "utils"
import { Button } from "components/button"
import { useToast } from "components/toast/Toast"
import usePopup from "hooks/usePopup"
import { Input } from "components/input"
import { Division } from "components"

const Style = styled.div`
  ${theme.card};
  .board {
    padding: 16px 0;
    display: flex;
    .column {
      width: 50%;
      & > * {
        margin: 16px 0;
      }
      h2 {
        margin-bottom: 24px;
      }
    }
  }
  .actions {
    & > * {
      margin-right: 16px;
    }
  }
  .list {
    .title {
      margin-bottom: 24px;
    }
    .more {
      margin-left: 8px;
    }
  }
`


const OrderDetail = () => {
  const params = useParams()
  const { confirm, alert } = usePopup()
  const { addToast } = useToast()
  const [ newAmount, setNewAmount ] = useState("")
  const [ data, setData ] = useState({
    id: 0, // id
    title: "", // 딜 이름
    name: null, // 이름
    email: "", // 이메일
    amount: "0", // 주문금액
    fee: "0", // 수수료
    order_status: "PENDING", // 주문상태
    stock_price: 0, // 주당가격
    stock_price_krw: 0, // 주당가격 (원)
    deposit_amount: "0", // 입금액
    deposit_code: "", // 입금코드
    order_at: new Date(), // 주문일
    confirm_at: null, // 입금완료일
    refund_at: null, // 환불일
    canceled_at: null, // 취소일
    uuid: "", // uuid
  })

  const onChangeNewAmount = e => {
    setNewAmount(e.target.value)
  }

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/orders/${params.uuid}`)
    setData(result.data)
  }, [params.uuid])

  const cancleOrder = () => {
    confirm(<>이 주문을 취소하시겠어요?</>, {
      title: '주문취소',
      onConfirm: async () => {
        const result = await axios.post(`/admin/orders/${params.uuid}/canceled`)
        addToast('주문취소 성공')
        setData(result.data)
      }
    })
  }

  const confirmOrder = async () => {
    confirm(<>입금이 완료되었나요?</>, {
      title: '입금 확인',
      onConfirm: async () => {
        const result = await axios.post(`/admin/orders/${params.uuid}/confirm`)  
        addToast('입금완료처리 되었습니다')
        setData(result.data)
      }
    })
  }

  const refundOrder = async () => {
    confirm(<>환불처리 하시겠어요?</>, {
      title: '환불',
      onConfirm: async () => {
        const result = await axios.post(`/admin/orders/${params.uuid}/refund`)  
        addToast('환불 성공')
        setData(result.data)
      }
    })
  }

  const increaseOrder = async () => {
    if (newAmount === "") return alert('증액할 금액을 입력해주세요')
    if (!Number(newAmount)) return alert('숫자만 입력해주세요')
    if (Number(data.amount) >= Number(newAmount)) return alert('증액 금액은 기존 주문 금액 이상이어야 합니다.')

    confirm(<>{newAmount}원으로 증액 하시겠어요?</>, {
      title: '주문 증액',
      onConfirm: async () => {
        const result = await axios.post(`/admin/orders/${params.uuid}/increase`, {new_amount: newAmount})
        addToast('증액처리 성공')
        setData(result.data)
      }
    })
  }

  useInit(fetchData)

  return (
    <DefaultLayout>
      <Style>
        <div className="actions flex f-h-end">
          <Button primary onClick={confirmOrder}>입금확인</Button>
          <Button negative onClick={cancleOrder}>주문취소</Button>
          <Button negative onClick={refundOrder}>환불처리</Button>
        </div>
        <div className="board">
          <div className="column">
            <h2>주문 정보</h2>
            <KeyValueLabel label="딜 이름" info={data.title}/>
            <KeyValueLabel label="주문금액" info={numberWithCommas(data.amount)}/>
            <KeyValueLabel label="수수료" info={numberWithCommas(data.fee)}/>
            <KeyValueLabel label="주당 가격" info={numberWithCommas(data.stock_price)}/>
            <KeyValueLabel label="주당 가격(원)" info={numberWithCommas(data.stock_price_krw)}/>
            <KeyValueLabel label="주문상태" info={data.order_status}/>
            <KeyValueLabel label="주문일" info={formatDate(data.order_at)}/>
            <div className="flex f-v-center">
              <Input name="newAmount"
                     placeholder="증액할 금액을 입력하세요"
                     value={newAmount}
                     onChange={onChangeNewAmount}
                     width="200px"/>
              <Division width="8px"/>
              <Button secondary onClick={increaseOrder}>증액</Button>
            </div>
          </div>
          <div className="column">
            <h2>주문자 정보</h2>
            <KeyValueLabel label="이메일" info={data.email}/>
            <KeyValueLabel label="이름" info={data.name}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>입금 정보</h2>
            <KeyValueLabel label="입금액" info={numberWithCommas(data.deposit_amount)}/>
            <KeyValueLabel label="입금코드" info={data.deposit_code}/>
            { data.confirm_at && <KeyValueLabel label="입금완료일" info={formatDate(data.confirm_at)}/>}
          </div>
          <div className="column">
            <h2>기타 정보</h2>
            { data.refund_at && <KeyValueLabel label="환불일자" info={formatDate(data.refund_at)}/>}
            { data.canceled_at && <KeyValueLabel label="취소일자" info={formatDate(data.canceled_at)}/>}
          </div>
        </div>

      </Style>
    </DefaultLayout>
  )
}

export default OrderDetail
