import theme from 'styles/theme';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

const Style = styled.div`
  display: block;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  overflow: hidden;
`

const ItemStyle = styled.div`
  width: 0px;
  height: 3px;
  opacity: 0.1;
  background-color: ${theme.positive(1)};
  transition: all 0.3s;
  &.loading {
    width: 30%;
    opacity: 1;
  }
  &.completed {
    animation: full 0.8s forwards;
  }
  @keyframes full {
    0% {
      width: 30%;
      opacity: 0.1;
    }
    30% {
      opacity: 1;
    }
    100% {
      width: 120%;
      opacity: 0.1;
    }
  }
`

const LoadingBar = ({ isLoading = false }) => {
  const [ mounted, setMounted ] = useState(false);
  const [ completed, setCompleted ] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setMounted(true);
    } else {
      setCompleted(true);
      setTimeout(() => {
        setMounted(false);
        setCompleted(false);
      }, 800);
    }
  }, [isLoading]);
  return (
    <Style mounted={mounted}>
      <ItemStyle className={completed ? 'completed' : (mounted ? 'loading' : '')}/>
    </Style>
  )
}

export default LoadingBar
