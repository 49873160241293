import theme from "styles/theme"
import styled from "styled-components"
import { memo } from "react";

const InputStyle = styled.input`
  width: ${({ width }) => width || '100%'};
  border: none;
  background-color: ${theme.nsd(0.04)};
  margin: 2px 0px;
  padding: 13px 13px 13px 13px;
  font-size: 16px;
  border-radius: 4px;
  &::placeholder {
    color: ${theme.nd(0.4)};
  }
  &:disabled {
    background-color: ${theme.nsd(0.12)};
    color: ${theme.nd(0.4)};
  }
`;

const CheckInputStyle = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.checked ? theme.info : theme.nd(0.2)};
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  transition: color 0.2s;
  input {
    display: none;
  }
  span {
    font-size: 13px;
    color: ${(props) => (props.checked ? theme.nd(1) : theme.nd(0.6))};
    transition: color 0.2s;
    &:hover {
      color: ${(props) => (props.checked ? theme.nd(1) : theme.nd(0.8))};
    }
  }
  div {
    padding: 4px;
    img {
      filter: ${(props) => (props.checked ? "none" : "saturate(0) brightness(1.2)")};
      transition: filter 0.3s;
    }
  }
`;

const SwitchBoxStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 58px;
  input[type="checkbox"].switch {
    position: relative;
    width: 58px;
    height: 32px;
    background-color: ${theme.nd(0.08)};
    border-radius: 16px;
    border: none;
    appearance: none;
    cursor: pointer;
    transition: all 0.2s;
    & + label {
      display: block;
      position: absolute;
      top: 2px;
      left: 3px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      transform: translateX(0px);
      animation: none;
      background-color: ${theme.nl(1)};
      transition: all 0.3s;
      box-shadow: 1px 2px 8px ${theme.nd(0.3)};;
      cursor: pointer;
    }
    &::before {
      display: none;
    }
    &:checked {
      background-color: ${theme.positive(1)};
      & + label {
        display: block;
        transform: translateX(26px);
      }
    }
  }
  input[type="checkbox"].switch::-ms-check {
    border: none;
    background-color: transparent;
    color: transparent;
  }
`

const RadioBoxStyle = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  label {
    margin-left: 14px;
    font-size: 18px;
    cursor: pointer;
  }
  input[type="radio"] {
    position: relative;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid ${theme.nd(0.1)};
    border-radius: 10px;
    cursor: pointer;
    &::after {
      position: absolute;
      display: block;
      top: 2px;
      left: 2px;
      content: ' ';
      width: 12px;
      height: 12px;
      background-color: ${theme.positive(1)};
      border-radius: 10px;
      transition: all 0.2s;
      transform: scale(0);
    }
    &:checked {
      &::after {
        transform: scale(1);
      }
    }
  }
`

export const CheckInput = ({ label, name, checked, onChange }) => {
  return (
    <CheckInputStyle checked={checked} onClick={() => {
      onChange({
        target: {
          name,
          value: !checked,
        },
      });
    }}>
      <div><img src="/assets/ic-check.svg" alt="check-icon"/></div>
      <span>{label}</span>
    </CheckInputStyle>
  );
};

export const SwitchBox = ({value, onChange, name}) => {
  return (
    <SwitchBoxStyle>
      <input type="checkbox" className="switch" id={name} name={name} checked={value} onChange={onChange}/>
      <label htmlFor={name}></label>
    </SwitchBoxStyle>
  ) 
}

export const Input = memo(InputStyle)

export const RadioBox = ({ label, name, onChange, checked }) => {
  return (
    <RadioBoxStyle>
      <input type="radio" id={name} name={name} checked={checked} onChange={onChange}/>
      <label htmlFor={name}>{label}</label>
    </RadioBoxStyle>
  )
}

export const TextArea = styled.textarea`
  width: ${({ width }) => width || '100%'};
  border: none;
  background-color: ${theme.nsd(0.04)};
  margin: 2px 0px;
  padding: 13px 13px 13px 13px;
  font-size: 16px;
  border-radius: 4px;
  &::placeholder {
    color: ${theme.nd(0.4)};
  }
  &:disabled {
    background-color: ${theme.nsd(0.12)};
    color: ${theme.nd(0.4)};
  }
`

export const Select = styled.select`
  width: ${({ width }) => width || '100%'};
  border: none;
  background-color: ${theme.nsd(0.04)};
  margin: 2px 0px;
  padding: 13px 13px 13px 13px;
  font-size: 16px;
  border-radius: 4px;
  &::placeholder {
    color: ${theme.nd(0.4)};
  }
  &:disabled {
    background-color: ${theme.nsd(0.12)};
    color: ${theme.nd(0.4)};
  }
`