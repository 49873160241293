import DefaultLayout from "layout/default"
import { useCallback, useState } from "react"
import styled from "styled-components"
import theme from "styles/theme"
import axios from "axios"
import { useParams } from 'react-router-dom'
import { useInit } from "hooks"
import KeyValueLabel from 'components/label/KeyValueLabel'
import { BooleanLabel } from "components/label/BooleanLabel"
import { formatDate } from "utils"
import BANK_CODE from "config/bank-code"
import { Button } from "components/button"
import { Division } from "components"
import DataTable from "components/table/DataTable"
import useHeader from "hooks/useHeader"
import { useToast } from "components/toast/Toast"
import usePopup from "hooks/usePopup"
import { Link } from 'react-router-dom'

const Style = styled.div`
  ${theme.card};
  .board {
    padding: 16px 0;
    display: flex;
    .column {
      width: 50%;
      & > * {
        margin: 16px 0;
      }
      h2 {
        margin-bottom: 24px;
      }
    }
  }
  .actions {
    & > * {
      margin-right: 16px;
    }
  }
  .list {
    .title {
      margin-bottom: 24px;
    }
    .more {
      margin-left: 8px;
    }
  }
`

const UserDetail = () => {
  const params = useParams()
  const { confirm } = usePopup()
  const { addToast } = useToast()
  const { orderHeaders, portfolioHeaders, userLikeHeaders } = useHeader()
  const [ data, setData ] = useState({
    address: {
      address1: "",
      address2: "",
      zipcode: "",
    },
    bank: {
      bank_account: "",
      bank_code: "",
    },
    stock_bank: {
      bank_account: "",
      bank_code: "",
    },
    closed_at: null,
    date_joined: "",
    email: "",
    id: 0,
    is_address_verified: false,
    is_bank_verified: false,
    is_stock_bank_verified: false,
    is_communities_muted: false,
    is_phone_verified: false,
    last_login: "",
    marketing_agreement: false,
    marketing_agreement_at: "",
    nickname: "",
    profile: {
      name: "",
      phone_number: "",
    },
    uuid: "",
    portfolios: [],
    like: [],
    orders: [],
  })

  const fetchData = useCallback(async () => {
    const result = await axios.get(`/admin/users/${params.uuid}`)
    setData(result.data)
  }, [params.uuid])

  const clearProfile = () => {
    confirm(<>초기화 하시겠습니까?</>, {
      title: '본인인증/계좌정보 초기화',
      onConfirm: async () => {
        const result = await axios.post(`/admin/users/${params.uuid}/clear_profile`)
        addToast('본인인증/계좌정보 초기화 성공')
        setData(result.data)
      }
    })
  }

  const marketingUnsubscribe = async () => {
    confirm(<>취소하시겠습니까?</>, {
      title: '마케팅 동의 취소',
      onConfirm: async () => {
        const result = await axios.post(`/admin/users/${params.uuid}/marketing_unsubscribe`)  
        addToast('마케팅 동의 취소 성공')
        setData(result.data)
      }
    })
  }

  const unsubscribe = async () => {
    confirm(<>탈퇴처리 하시겠습니까?</>, {
      title: '회원탈퇴',
      onConfirm: async () => {
        const result = await axios.post(`/admin/users/${params.uuid}/unsubscribe`)  
        addToast('회원탈퇴 성공')
        setData(result.data)
      }
    })
  }

  useInit(fetchData)

  return (
    <DefaultLayout>
      <Style>
        <div className="actions flex f-h-end">
          <Button secondary onClick={clearProfile}>본인인증/계좌정보 초기화</Button>
          <Button secondary onClick={marketingUnsubscribe}>마케팅 동의 취소</Button>
          <Button negative onClick={unsubscribe}>회원 탈퇴</Button>
        </div>
        <div className="board">
          <div className="column">
            <h2>시스템 정보</h2>
            <KeyValueLabel label="UUID" info={data.uuid}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>기본 정보</h2>
            <KeyValueLabel label="이메일" info={data.email}/>
            <KeyValueLabel label="닉네임" info={data.nickname}/>
            <KeyValueLabel label="이름" info={data.profile.name}/>
            <KeyValueLabel label="휴대폰번호" info={data.profile.phone_number}/>
          </div>
          <div className="column">
            <h2>인증 정보</h2>
            <KeyValueLabel label="본인인증" info={<BooleanLabel status={data.is_phone_verified}/>}/>
            <KeyValueLabel label="주소인증" info={<BooleanLabel status={data.is_address_verified}/>}/>
            <KeyValueLabel label="환불 계좌인증" info={<BooleanLabel status={data.is_bank_verified}/>}/>
            <KeyValueLabel label="주식 증권 계좌인증" info={<BooleanLabel status={data.is_stock_bank_verified}/>}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>은행 정보</h2>
            <KeyValueLabel label="환불 은행명" info={BANK_CODE[data.bank.bank_code]}/>
            <KeyValueLabel label="환불 계좌번호" info={data.bank.bank_account}/>
            <KeyValueLabel label="주식 입고 은행명" info={BANK_CODE[data.stock_bank.bank_code]}/>
            <KeyValueLabel label="주식 입고 계좌번호" info={data.stock_bank.bank_account}/>
          </div>
          <div className="column">
            <h2>주소 정보</h2>
            <KeyValueLabel label="우편번호" info={data.address.zipcode}/>
            <KeyValueLabel label="주소1" info={data.address.address1}/>
            <KeyValueLabel label="주소2" info={data.address.address2}/>
          </div>
        </div>
        <div className="board">
          <div className="column">
            <h2>동의 사항</h2>
            <KeyValueLabel label="마케팅 동의" info={<BooleanLabel status={data.marketing_agreement}/>}/>
            { data.marketing_agreement && <KeyValueLabel label="마케팅 동의일" info={formatDate(data.marketing_agreement_at)}/> }
          </div>
          <div className="column">
            <h2>활동 정보</h2>
            <KeyValueLabel label="가입일시" info={formatDate(data.date_joined)}/>
            <KeyValueLabel label="마지막 로그인" info={data.last_login ? formatDate(data.last_login) : '정보없음'}/>
            <KeyValueLabel label="커뮤니티 이용제한" info={<BooleanLabel status={!data.is_communities_muted}/>}/>
            <KeyValueLabel label="탈퇴여부" info={<BooleanLabel status={data.closed_at}/>}/>
            { data.colsed_at && <KeyValueLabel label="탈퇴일" info={formatDate(data.closed_at)}/> }
          </div>
        </div>

        <Division height="64px"/>

        <div className="list">
          <div className="title flex f-v-center">
            <h2>주문 내역</h2>
            <Link className="more" to={`/admin/user/${params.uuid}/orders?email=${data.email}`}>전체보기</Link>
          </div>

          <DataTable headers={orderHeaders} data={data.orders}/>
        </div>

        <Division height="64px"/>

        <div className="list">
          <div className="title flex f-v-center">
            <h2>딜 참여 내역</h2>
          </div>
          
          <DataTable headers={portfolioHeaders} data={data.portfolios}/>
        </div>

        <Division height="64px"/>

        <div className="list">
          <div className="title flex f-v-center">
            <h2>알림신청 내역</h2>
            <Link className="more" to={`/admin/user/${params.uuid}/likes?email=${data.email}`}>전체보기</Link>
          </div>
          
          <DataTable headers={userLikeHeaders} data={data.like}/>
        </div>
      </Style>
    </DefaultLayout>
  )
}

export default UserDetail
