import theme from 'styles/theme'
import styled from 'styled-components'
import { Input, SwitchBox, TextArea } from 'components/input'
import UploadButton from 'components/button/UploadButton'
import { Division } from 'components'
import { Button } from 'components/button'
import { keyUpEnter } from 'utils'
import { useInit, useInput } from 'hooks'
import { useCallback, useState } from 'react'
import axios from 'axios'
import TagLabel from 'components/label/TagLabel'
import IcPlus from 'svg/IcPlus'
import config from 'config/config'

const Style = styled.div`
  .guide-link {
    display: block;
    padding: 16px 24px;
    background-color: ${theme.positive(0.05)};
    color: ${theme.positive(1)};
    border: 1px solid ${theme.positive(1)};
    margin: 16px 0;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
  }
  .grid-board {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
  .row {
    margin: 24px 0;
    .label {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
  .new-tag {
  }
  .tag-list {
    ${theme.card}
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin: 4px;
    }
  }
  .selected-tag-item {
    display: flex;
    align-items: center;
    .remove-button {
      margin-left: 4px;
      width: 16px;
      height: 16px;
      background-color: ${theme.nd(0.6)};
      transform: rotate(45deg);
      border-radius: 16px;
      cursor: pointer;
    }
  }
`

const CompanyTemplate = ({ state, onChange }) => {
  const [ tags, setTags ] = useState([])
  const [ tagInput, tagInputOnChange ] = useInput({
    newTag: '',
  }) 

  const addTag = tag => {
    onChange({
      target: {
        name: 'tags',
        value: [
          ...state.tags,
          tag
        ]
      },
    })

    tagInputOnChange({
      target: {
        name: 'newTag',
        value: '',
      }
    })
  }

  const removeTag = tag => {
    onChange({
      target: {
        name: 'tags',
        value: state.tags.reduce((acc, value) => {
          if (tag !== value) {
            acc.push(value)
          }
          return acc;
        }, [])
      }
    })
  }

  const fetchTags = useCallback(async () => {
    const result = await axios.get('/admin/tags')
    setTags(result.data)
  }, [])

  useInit(fetchTags)

  return (
    <Style>
      <a className="guide-link" href={config.COMPANY_UPLOAD_GUIDE} target="_blank" rel="noreferrer">
        📜  회사정보 작성 가이드
      </a>
      <div className="grid-board">
          <div className="board-section">
            <div className="row">
              <div className="label">
                회사명 (영문)
              </div>
              <Input name="name"
                    width="343px"
                    value={state.name}
                    onChange={onChange}
                    placeholder="영문 회사명 입력"/>
            </div>
            <div className="row">
              <div className="label">
                회사명 (한글)
              </div>
              <Input name="kr_name"
                    width="343px"
                    value={state.kr_name}
                    onChange={onChange}
                    placeholder="한글 회사명 입력"/>
            </div>
            <div className="row">
              <div className="label">
                URL
              </div>
              <Input name="url"
                    width="343px"
                    value={state.url}
                    onChange={onChange}
                    placeholder="ex) https://twig.money"/>
            </div>
            <div className="row">
              <div className="label">
                설립년도
              </div>
              <Input name="established"
                    width="343px"
                    value={state.established}
                    onChange={onChange}
                    placeholder="2011"/>
            </div>
            <div className="row">
              <div className="label">
                짧은소개
              </div>
              <Input name="introduction"
                    width="343px"
                    value={state.introduction}
                    onChange={onChange}
                    placeholder="짧은소개 입력"/>
            </div>
            <div className="row">
              <div className="label">
                상세소개
              </div>
              <TextArea name="detail"
                    width="343px"
                    rows="10"
                    value={state.detail}
                    onChange={onChange}
                    placeholder="상세소개 입력"/>
            </div>
            <div className="row">
              <div className="label">
                투자자
              </div>
              <TextArea name="investors"
                    width="343px"
                    rows="5"
                    value={state.investors}
                    onChange={onChange}
                    placeholder="투자자 입력"/>
            </div>
          </div>

          <div className="board-section">

            <div className="row">
              <div className="label">로고 (144 X 144)</div>
              <UploadButton url={state.icon}
                            onChange={onChange}
                            id="company-icon"
                            name="icon"
                            width="144px"
                            height="144px"/>
            </div>

            <div className="row">
              <div className="label">배너 (800 X 320)</div>
              <UploadButton url={state.banner}
                            onChange={onChange}
                            id="company-banner"
                            name="banner"
                            width="428px"
                            height="171px"/>
            </div>

            <div className="row">
              <div className="label">태그</div>
              <div className="new-tag flex">
                <Input name="newTag"
                       width="343px"
                       value={tagInput.newTag}
                       onChange={tagInputOnChange}
                       onKeyUp={e => keyUpEnter(e, () => {
                        addTag(e.target.value)
                       })}
                       placeholder="태그 추가"/>
                <Division width="8px"/>
                <Button onClick={() => {addTag(tagInput.newTag)}} size="small" primary>
                  태그 추가
                </Button>
              </div>
              <Division height="16px"/>
              <div className="tag-list">
                { tags.map(tagObject => <TagLabel tag={tagObject.name} key={tagObject.name} onClick={addTag}/>)}
              </div>
              <Division height="32px"/>
              <h4>선택된 태그</h4>
              <Division height="16px"/>
              <div className="tag-list">
                { state.tags.map(tagName => (
                  <div className="selected-tag-item" key={tagName}>
                    <TagLabel tag={tagName}/>
                    <div className="remove-button"
                         onClick={() => {removeTag(tagName)}}>
                      <IcPlus color={theme.nl(1)} width={16} height={16}/>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="label">
                트위터
              </div>
              <Input name="twitter"
                     width="343px"
                     value={state.twitter}
                     onChange={onChange}
                     placeholder="@제외하고 입력"/>
            </div>

            <div className="row">
              <div className="label">
                활성
              </div>
              <SwitchBox value={state.is_active}
                         onChange={e => {
                           const params = {
                             target: {
                               value: e.target.checked,
                               name: e.target.name
                             }
                           }
                           onChange(params)
                          }}
                         name="is_active"/>
            </div>

          </div>
        </div>
    </Style>
  )
}

export default CompanyTemplate
