import { useRecoilState } from 'recoil'
import { popupIdCount, popupList } from 'recoil/GlobalStore'

const usePopup = () => {
  const [ popupListValue, setPopupList ] = useRecoilState(popupList)
  const [ popupIdCountValue, setPopupIdCount ] = useRecoilState(popupIdCount)

  const confirm = (children, options = { title: null, onConfirm: () => {}}) => {
    const newArr = [...popupListValue, {
      type: 'confirm',
      id: popupIdCountValue,
      children,
      title: options.title,
      onConfirm: options.onConfirm,
    }]
    setPopupList(newArr)
    setPopupIdCount(popupIdCountValue + 1)
  }

  const alert = (children, options = { title: null, onConfirm: () => {}}) => {
    const newArr = [...popupListValue, {
      type: 'alert',
      id: popupIdCountValue,
      children,
      title: options.title,
      onConfirm: options.onConfirm,
    }]
    setPopupList(newArr)
    setPopupIdCount(popupIdCountValue + 1)
  }

  const removePopup = id => {
    for (let i = 0; popupListValue.length > i; i++) {
      if (popupListValue[i].id === id) {
        const newArr = [
          ...popupListValue.slice(0, i),
          ...popupListValue.slice(i + 1)
        ]
        setPopupList(newArr)
        break
      }
    }
  }

  return { confirm, alert, removePopup }
}

export default usePopup
