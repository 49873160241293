import theme from 'styles/theme'
import styled from 'styled-components'
import DefaultLayout from 'layout/default';
import { useCallback, useState } from 'react';
import axios from 'axios';
import DataTable from 'components/table/DataTable';
import { Division } from 'components';
import { useInit } from 'hooks';
import Pagination from 'components/pagination/Pagination';
import useHeader from 'hooks/useHeader';
import { Button } from 'components/button';

const Style = styled.div`
  ${theme.card}
  .filters {
    gap: 8px;
    .by-status {
      select {
        border: 1px solid ${theme.nd(0.2)};
        border-radius: 4px;
        height: 48px;
        padding: 0 8px;
      }
    }
    .by-deal {
      position: relative;
      .deal-filter-button {
        border: 1px solid ${theme.nd(0.2)};
        border-radius: 4px;
        height: 48px;
        padding: 0 16px;
        text-align: left;
        min-width: 160px;
      }
      .deal-filter {
        ${theme.card}
        position: absolute;
        transform: translateX(-50%);
        padding: 16px;
        .deal-list {
          width: 320px;
          .deal-item {
            cursor: pointer;
            padding: 8px;
            border: 1px solid ${theme.nd(0.08)};
            background-color: ${theme.nd(0.00)};
            border-radius: 4px;
            margin: 8px 0;
            transition: all 0.3s;
            &:hover {
              background-color: ${theme.nd(0.04)};
            }
          }
        } 
      }
    }
  }
`

const CommentList = () => {
  const { commentHeaders } = useHeader()
  const [ comments, setComments ] = useState([])
  const [ pageLength, setPageLength ] = useState(1)
  const [ current, setCurrent ] = useState(1)
  const [ dealFilter, setDealFilter ] = useState({
    id: "",
    title: ""
  })


  const [ dealFilterState, setDealFilterState ] = useState(false)
  const [ deals, setDeals ] = useState([])
  const [ dealCurrent, setDealCurrent ] = useState(1)
  const [ dealPageLength, setDealPageLength] = useState(1)


  const fetchData = useCallback(async (page, filter = { deal: '' }) => {
    if (page) {
      setCurrent(page)
    }

    const { deal } = filter

    const params = {}
    if (page) params.page = page
    if (deal) params.deal = deal

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/comments?${queryString}`)
    setComments(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setPageLength(len)
    } else {
      setPageLength(1)
    }
  }, [])


  const toggleDealFilter = async page => {
    setDealFilterState(true)
    if (page) {
      setDealCurrent(page)
    }

    const params = {}
    if (page) params.page = page

    const queryString = new URLSearchParams(params).toString()
    
    const result = await axios.get(`/admin/deals?${queryString}`)
    setDeals(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setDealPageLength(len)
    }
  }

  const paginationHandler = async page => {
    await fetchData(page, { deal: dealFilter.id })
  }

  useInit(fetchData)


  return (
    <DefaultLayout>
      <Style>
        <div className="flex f-v-center f-h-between">
          <h2>댓글 관리</h2>
          <div className="filters flex f-v-center">
            <h4>필터</h4>
            <div className="by-deal">
              <button className="deal-filter-button" onClick={() => {toggleDealFilter(dealCurrent)}}>
                { dealFilter.title || '딜 선택'}
              </button>
              { dealFilterState && <div className="deal-filter">
                <div className="deal-list">
                  { deals.map(deal => (<div className="deal-item" key={deal.id} onClick={() => {
                    setDealFilter(deal)
                    setDealFilterState(false) 
                  }}>
                      {deal.title}
                    </div>
                  ))}
                </div>
                <Pagination current={dealCurrent} length={dealPageLength} fetchData={toggleDealFilter}/>
              </div>}
            </div>
            <Button primary onClick={() => {fetchData(0, { deal: dealFilter.id })}}>적용</Button>
            <Button secondary onClick={() => {fetchData(0)}}>초기화</Button>
          </div>
        </div>
        <Division height="24px"/>
        <DataTable headers={commentHeaders} data={comments}/>
        <Division height="64px"/>
        <Pagination current={current} length={pageLength} fetchData={paginationHandler}/>
        <Division height="64px"/>
      </Style>
    </DefaultLayout>
  )
}

export default CommentList
