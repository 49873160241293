import theme from 'styles/theme'
import styled from 'styled-components'
import { useState } from 'react';
import axios from 'axios';
import Pagination from 'components/pagination/Pagination';
import { Button } from 'components/button';
import dictionary from 'utils/translator';

const OrderFilter = ({ setOrderStatus, dealFilter, setDealFilter, orderStatus, fetchData }) => {
  const [ dealFilterState, setDealFilterState ] = useState(false)
  const [ deals, setDeals ] = useState([])
  const [ dealCurrent, setDealCurrent ] = useState(1)
  const [ dealPageLength, setDealPageLength] = useState(1)

  const orderStatusFilterList = ['', 'PENDING', 'CANCELED', 'CONFIRM', 'WILL_REFUND', 'PARTIALLY_CONFIRM', 'OVER_FUNDING', 'REFUND']

  const toggleDealFilter = async page => {
    setDealFilterState(true)
    if (page) {
      setDealCurrent(page)
    }

    const params = {}
    if (page) params.page = page

    const queryString = new URLSearchParams(params).toString()
    
    
    const result = await axios.get(`/admin/deals?${queryString}`)
    setDeals(result.data.results)
    if (result.data.count) {
      const len = result.data.count / 20 + 1
      setDealPageLength(len)
    }
  }

  const selectStatus = async e => {
    const status = e.target.value
    setOrderStatus(status)
    await fetchData(0, { order_status: status, deal: dealFilter.uuid })
  }

  const dealItemClickHandler = async deal => {
    setDealFilter(deal)
    setDealFilterState(false)
    await fetchData(0, { order_status: orderStatus, deal: deal.uuid })
  }

  const initalize = async () => {
    setDealFilter({
      uuid: "",
      title: ""
    })
    setOrderStatus("")
    await fetchData(0, { order_status: "", deal: "" })
  }

  return (
    <FilterStyle className="flex f-v-center">
      <h4>필터</h4>
      <div className="by-deal">
        <button className="deal-filter-button" onClick={() => {toggleDealFilter(dealCurrent)}}>
          { dealFilter.title || '딜 선택'}
        </button>
        { dealFilterState && <div className="deal-filter">
          <div className="deal-list">
            { deals.map(deal => (<div className="deal-item" key={deal.uuid} onClick={() => {dealItemClickHandler(deal)}}>
                {deal.title}
              </div>
            ))}
          </div>
          <Pagination current={dealCurrent} length={dealPageLength} fetchData={toggleDealFilter}/>
        </div>}
      </div>
      <div className="by-status">
        <select onChange={selectStatus} value={orderStatus}>
          { orderStatusFilterList.map(status => 
            <option value={status} key={status}>
              {status ? dictionary.order_status[status] : '전체'}
            </option>
          )}
        </select>
      </div>
      <Button secondary onClick={initalize}>초기화</Button>
    </FilterStyle>
  )
}

const FilterStyle = styled.div`
  gap: 8px;
  .by-status {
    select {
      border: 1px solid ${theme.nd(0.2)};
      border-radius: 4px;
      height: 48px;
      padding: 0 8px;
    }
  }
  .by-deal {
    position: relative;
    .deal-filter-button {
      border: 1px solid ${theme.nd(0.2)};
      border-radius: 4px;
      height: 48px;
      padding: 0 16px;
      text-align: left;
      min-width: 160px;
    }
    .deal-filter {
      ${theme.card}
      position: absolute;
      transform: translateX(-50%);
      padding: 16px;
      .deal-list {
        width: 320px;
        .deal-item {
          cursor: pointer;
          padding: 8px;
          border: 1px solid ${theme.nd(0.08)};
          background-color: ${theme.nd(0.00)};
          border-radius: 4px;
          margin: 8px 0;
          transition: all 0.3s;
          &:hover {
            background-color: ${theme.nd(0.04)};
          }
        }
      } 
    }
  }
`

export default OrderFilter
