import theme from 'styles/theme'
import styled from 'styled-components'
import { Button } from 'components/button'
import { useEffect, useState } from 'react'
import { EmptyComponent } from 'components'
import usePopup from 'hooks/usePopup'
import { useRecoilValue } from 'recoil'
import { popupList } from 'recoil/GlobalStore'

const Style = styled.div`
  display: ${props => props.status ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

const PopupStyle = styled.div`
  .layer {
    position: relative;
    background-color: ${theme.nl(1)};
    z-index: 101;
    border-radius: 16px;
    min-width: 280px;
    width: calc(100% - 48px);
    max-width: 450px;
    padding: 36px 24px 24px;
    box-shadow: 2px 6px 8px ${theme.nd(0.04)};
    margin: 0 auto;
    .title {
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 23px;
      max-width: 180px;
      line-height: 1.45;
      word-break: keep-all;
    }
    .message {
      line-height: 1.45;
      word-break: keep-all;
    }
    .actions {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      & > * {
        margin-right: 8px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
    transform: ${props => props.active ? 'translateY(0px)' : 'translateY(30px)'};
    opacity: ${props => props.active ? '1' : '0'};
    transition: all 0.3s;
  }
  .dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.active ? theme.nd(0.1) : theme.nd(0)};
    transition: all 0.3s;
  }
`

const PopupItem = ({ 
  id,
  type,
  title,
  children = <EmptyComponent/>,
  onConfirm = () => {},
  onCancel = () => {},
  removePopup }) => {
  
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 10)
  }, [])

  const cancel = () => {
    setActive(false)
    onCancel()
    setTimeout(() => {
      removePopup(id)
    }, 300)
  }
  
  const confirm = () => {
    setActive(false)
    onConfirm()
    setTimeout(() => {
      removePopup(id)
    }, 300)
  }

  return (
    <PopupItemView
      type={type}
      active={active}
      title={title}
      confirm={confirm}
      cancel={cancel}>
      {children}
    </PopupItemView>
  )
}

const PopupManager = () => {
  const popupListValue = useRecoilValue(popupList)
  const { removePopup } = usePopup()

  return (
    <Style status={popupListValue.length > 0}>
      {
        popupListValue.map(item => (
          <PopupItem 
            key={item.id}
            removePopup={removePopup}
            {...item}>
          </PopupItem>
        ))
      }
    </Style>
  )
}

const PopupItemView = ({
  type,
  active,
  title,
  children,
  cancel,
  confirm
}) => {
  return (
    <PopupStyle active={active}>
      <div className="layer">
        {title && <div className="title">
          { title }
        </div>}
        <div className="message">
          { children }
        </div>
        <div className="actions">
          { type === 'confirm' && <Button className="popup secondary" onClick={cancel}>취소</Button>}
          <Button className="popup" onClick={confirm}>확인</Button>
        </div>
      </div>
      <div className="dim"></div>
    </PopupStyle>
  )
}

export default PopupManager
export { PopupItemView }

