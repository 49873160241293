import theme from 'styles/theme'
import styled from 'styled-components'

const Style = styled.div`
  background-color: ${({ color }) => color};
  color: ${theme.nd(0.7)};
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
`

const TagLabel = ({ tag, onClick = () => {} }) => {
  const colors = [
    '#D3EAFF',
    '#DFD3FF',
    '#DFFFD3',
    '#DFFFD3',
    '#F9FFD3',
    '#FFD3DB',
    '#EFEFEF',
    '#D3DDFF',
    '#FFE8D3',
    '#FFD3FD',
    '#D3FFEA',
  ]
  const color = colors[tag[0].charCodeAt() % 10]
  return (
    <Style color={color} onClick={() => {onClick(tag)}}>
      {tag}
    </Style>
  )
}

export default TagLabel
